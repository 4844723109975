import { CalendarDaysIcon, InformationCircleIcon, VideoCameraIcon, } from "@heroicons/react/24/outline";
import Button from "../../atoms/Button";
import styles from "./AppointmentWidget.module.scss";
import circeShape from "../../../theme/images/circleShape.png";
import trangleShape from "../../../theme/images/triangleShape.png";
import { capitalize } from "../../../utils/capitalize";
import InfoBox from "../../atoms/InfoBox";
import { useEffect, useState } from "react";
import { getNormalizedDate } from "../../../utils/dateTime";
import { disableAppointmentButton } from "../../../utils/disableAppointmentButton";
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';
import TherapistWarningButtonModal from '../../Modals/TherapistWarningButtonModal';

type Variant = "warn" | "info" | "confirm" | "error" | undefined;

interface Props {
  messageId: number;
  appointment: any;
  isFirstAppointment: null | "x";
  widgetStatus:
    | "payed_to_be_confirmed"
    | "waiting_for_confirm"
    | "ready"
    | "moved"
    | 'payed_moved'
    | "cancelled"
    | "completed"
    | "expired";
  userRole: "patient" | "therapist";
  startTime: string;
  endTime: string;
  onModify: (appointmentId: any, messageId: number) => void;
  onAccept: (appointmentId: string, messageId: number, isPayedMoved?: boolean) => void;
  onPatientPayment: () => void;
  onReady: (appointmentId: string) => void;
  createdByPatient?: any;
  therapyType: string;
}

export const AppointmentWidget = ({
  messageId,
  widgetStatus,
  isFirstAppointment,
  createdByPatient,
  appointment,
  startTime,
  endTime,
  userRole,
  onModify,
  onAccept,
  onPatientPayment,
  onReady,
  therapyType
}: Props) => {

  const isPsychiatry = therapyType === "psychiatry"
  const navigate = useNavigate();

  const [widgetState, setWidgetState] = useState<any>(null);
  const [isModal, setIsModal] = useState<boolean>(false);

  const expiredAppointmentButtonContentForPatient = () => {
    return {
      title: 'Appuntamento Cancellato',
      text: (
        <div>
          L'appuntamento è stato cancellato perché non è stato confermato entro il tempo limite. {isPsychiatry && (
          <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => {
                  navigate(routes.PatientBookingFirstAppointment.path);
                }}
          >
            Clicca qui
          </span>
        )} per prenotare un nuovo appuntamento.
        </div>
      ),
    };
  }

  const expiredAppointmentButtonContentForTherapist = () => {
    return {
      title: "Appuntamento Cancellato",
      text: (
        <div>
          L'appuntamento è stato cancellato perché non è stato confermato
          entro il tempo limite.{" "}
            <span onClick={() => onModify(appointment, messageId)} style={{textDecoration: "underline", cursor: "pointer"}}>
              Sposta
            </span>
        </div>
      ),
    };
  }

  const buttonsContentForTherapist = () => {
    if (createdByPatient) {
      return {
        buttonText: "Accetta",
        widgetText: "PRIMO APPUNTAMENTO",
        variant: "confirm" as Variant,
      };
    }
    if (isFirstAppointment !== null) {
      return {
        buttonText: "Collegati",
        widgetText: "PRIMO APPUNTAMENTO",
        variant: "confirm" as Variant,
      };
    } else {
      return {
        buttonText: "Collegati",
        widgetText: "IN ATTESA DI PAGAMENTO",
        variant: "warn" as Variant,
      };
    }
  }

  const getWidgetState = (appointmentData: any) => {
    const isExpired = +getNormalizedDate(startTime) <= +getNormalizedDate();
    const getPatientState = () => {
      switch (widgetStatus) {
        case "waiting_for_confirm":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForPatient();
            return { title, text }
          }
          if (createdByPatient && paymentIntentId) {
            return { buttonText: "Conferma" };
          }
          return { buttonText: "Accetta" };
        case "payed_to_be_confirmed":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForPatient();
            return { title, text }
          }
          return { buttonText: "Collegati" };
        case "ready":
          return { buttonText: "Collegati" };
        case "completed":
          return { buttonText: "Collegati" };
        case "moved":
          return {
            title: "Appuntamento Spostato",
            text: "Il terapeuta ha spostato questo appuntamento.",
          };
        case "payed_moved":
          return {
            title: "Appuntamento Spostato",
            text: "Ci dispiace, il medico ha dovuto spostare l’appuntamento per un’urgenza. Puoi accettarlo o concordare uno nuovo in chat.",
            buttonText: "Accetta"
        };
        case "cancelled":
          return {
            title: "Appuntamento Cancellato",
            text:
              appointmentData?.cancelledBy === "patient"
                ? "Hai scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con il terapeuta per scegliere un altro giorno."
                : "Il tuo terapeuta ha scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con lui per scegliere un altro giorno.",
          };
        case "expired":
          const { title, text} = expiredAppointmentButtonContentForPatient();
          return { title, text }
        default:
          return { buttonText: "Accetta" };
      }
    };

    const getTherapistState = () => {
      const { buttonText, widgetText, variant } = buttonsContentForTherapist();
      switch (widgetStatus) {
        case "waiting_for_confirm":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForTherapist();
            return { title, text }
          }
          return { buttonText, text: widgetText, variant }
        case "payed_to_be_confirmed":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForTherapist();
            return { title, text }
          }
          return { buttonText, text: widgetText, variant }
        case "ready":
          return {
            buttonText: "Collegati",
            text: "PAGATO",
            variant: "confirm" as Variant,
          };
        case "completed":
          return {
            buttonText: "Collegati",
            text: "PAGATO",
            variant: "confirm" as Variant,
          };
        case "moved":
          return {
            title: "Appuntamento Spostato",
            text: "Hai scelto di spostare l'appuntamento.",
          };
        case 'payed_moved':
          return {
            title: "Appuntamento Spostato",
            text: "Ci dispiace, il medico ha dovuto spostare l’appuntamento per un’urgenza. Puoi accettarlo o concordare uno nuovo in chat.",
            buttonText: 'Collegati',
            variant: 'confirm' as Variant,
          };
        case "cancelled":
          return {
            title: "Appuntamento Cancellato",
            text:
              appointmentData?.cancelledBy === "therapist"
                ? "Hai scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con il paziente per scegliere un altro giorno."
                : "Il paziente ha scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con lui per scegliere un altro giorno.",
          };
        case "expired":
          const { title, text} = expiredAppointmentButtonContentForTherapist();
          return { title, text }
        default:
          return {};
      }
    };

    return userRole === "patient" ? getPatientState() : getTherapistState();
  };

  const [isCompleted, setIsCompleted] = useState<boolean>();
  const [isPast, setIsPast] = useState<boolean>();
  const [isPastPayedMoved, setIsPastPayedMoved] = useState<boolean>();

  useEffect(() => {
    setWidgetState(getWidgetState(appointment));
    setIsPast(+getNormalizedDate(appointment.endTime) < +getNormalizedDate());
    setIsPastPayedMoved(+getNormalizedDate() > +getNormalizedDate(appointment.endTime) + 5 * 24 * 60 * 60 * 1000);
    setIsCompleted(
      +getNormalizedDate(appointment.endTime) + 1000 * 60 * 60 <
      +getNormalizedDate()
    );
    const interval = setInterval(() => {
      setWidgetState(getWidgetState(appointment));
      setIsCompleted(
        +getNormalizedDate(appointment.endTime) + 1000 * 60 * 60 <
        +getNormalizedDate()
      );
      setIsPast(+getNormalizedDate(appointment.endTime) < +getNormalizedDate());
      setIsPastPayedMoved(+getNormalizedDate() > +getNormalizedDate(appointment.endTime) + 5 * 24 * 60 * 60 * 1000);
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [appointment]);
  

  const appointmentDate = () => {
    const date = getNormalizedDate(startTime).toLocaleDateString("it", {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    const arrayDate = date.split(" ");
    arrayDate[0] = capitalize(arrayDate[0]) + ",";
    arrayDate[2] = capitalize(arrayDate[2]);

    return arrayDate.join(" ");
  };

  const appointmentHour = () => {
    const startDate = getNormalizedDate(startTime).toLocaleString("IT", {
      hour: "2-digit",
      minute: "2-digit",
    })
    const endDate = getNormalizedDate(endTime).toLocaleString("IT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return startDate + (isFirstAppointment && userRole === "patient" ? '' : ' - ' + endDate);
  };

  const paymentIntentId = appointment.paymentIntentId
  const isTherapist = userRole === "therapist";
  const isPatient = userRole === "patient";
  const commonConditions = appointment.moveRequest || isCompleted;

  const isDisabled = disableAppointmentButton(paymentIntentId, isTherapist, isPatient, createdByPatient, widgetStatus, commonConditions, isPast);
  const isHidden = isTherapist && (widgetStatus === 'waiting_for_confirm' && createdByPatient && paymentIntentId);

  return (
    <div className={styles.widget}>
      {isModal && <TherapistWarningButtonModal setClose={() => setIsModal(false)}/>}
      {isFirstAppointment && !isPsychiatry && <p className={styles.title}>Primo incontro gratuito</p>}
      <div className={styles.dateWrapper}>
        <div className={styles.calendar}>
          <div className={styles.icon}>
            <CalendarDaysIcon />
          </div>
          <div className={styles.date}>
            <div className={styles.day}>{appointmentDate()}</div>
            <div className={styles.hour}>{appointmentHour()}</div>
          </div>
        </div>
        <div className={styles.shapes}>
          <img
            className={styles.circleShape}
            src={circeShape}
            alt="circle shape"
          />
          <img
            className={styles.trangleShape}
            src={trangleShape}
            alt="triangle shape"
          />
        </div>
      </div>
      {widgetStatus === "waiting_for_confirm" && (
        <div className={styles.moveRequestLabel}>
          <InformationCircleIcon width={17} />{" "}
          {isFirstAppointment && !isPsychiatry
            ? "In attesa di conferma"
            : "In attesa di pagamento"}
        </div>
      )}
      {!isPast && widgetStatus === 'payed_moved' && (
        <InfoBox
          className={styles.infoBox}
          variant="warn"
          title={widgetState?.title || getWidgetState(appointment)?.title}
          text={widgetState?.text || getWidgetState(appointment)?.text}
        />
      )}
      {!isPast && widgetStatus === "payed_to_be_confirmed" && (
        <div className={styles.moveRequestLabel}>
          <InformationCircleIcon width={17} />{" "}
          {isPatient
            ? "In attesa di conferma del professionista"
            : "Confermato e pagato dal paziente, da confermare"
          }
        </div>
      )
      }
      {!isPast && widgetStatus === "ready" && (
        <div className={styles.paidLabel}>
          <InformationCircleIcon width={17} />{" "}
          {isFirstAppointment && !isPsychiatry ? "Confermato" : "Pagato"}
        </div>
      )}
      {(
        appointment.status === "waiting_for_confirm" ||
        appointment.status === "payed_to_be_confirmed" ||
        appointment.status === "ready"
        ) &&
          !isCompleted &&
          appointment.moveRequest && (
            <div className={styles.moveRequestLabel}>Richiesto spostamento</div>
        )}
      {widgetState?.buttonText ||
        getWidgetState(appointment)?.buttonText ? (
          <div className={styles.btnWrapper}>
            <Button
              className={styles.firstBtn}
              variant="tertiary"
              onClick={() => onModify(appointment, messageId)}
              disabled={isTherapist && widgetStatus === 'payed_moved' ? isPastPayedMoved : isPast || widgetStatus === 'payed_moved'}
            >
              Modifica
            </Button>
            <Button
              className={`${styles.secondBtn} ${isHidden && styles.hidden}`}
              disabled={isDisabled}
              onClick={() => {
                switch (widgetStatus) {
                  case "payed_to_be_confirmed":
                    if (createdByPatient) {
                      return onAccept(appointment.id, messageId);
                    }
                    break;
                  case "waiting_for_confirm":
                    if (isFirstAppointment !== null && !isPsychiatry) {
                      return onAccept(appointment.id, messageId);
                    }
                    if (isHidden) {
                      return setIsModal(true);
                    }
                    return onPatientPayment();
                  case "ready":
                    return onReady(appointment.id);
                  case "completed":
                    return onReady(appointment.id);
                  case 'payed_moved':
                    return onAccept(appointment.id, messageId, true);
                }
              }}
              leftIcon={
                widgetStatus === "ready" ? (
                  <VideoCameraIcon />
                ) : null
              }
            >
              {getWidgetState(appointment)?.buttonText ||
                widgetState?.buttonText}
            </Button>
          </div>
        ) : (
          <InfoBox
            className={styles.infoBox}
            variant="warn"
            title={
              widgetState?.title || getWidgetState(appointment)?.title
            }
            text={
              widgetState?.text || getWidgetState(appointment)?.text
            }
          />
        )}
    </div>
  );
};
