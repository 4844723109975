import { envs } from './envs';

export const sendWhatsappMessages = async (body: any) => {
  await fetch(`https://graph.facebook.com/v${envs.REACT_APP_WHATSAPP_VERSION}/${envs.REACT_APP_WHATSAPP_PHONE_NUMBER_ID}/messages`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${envs.REACT_APP_WHATSAPP_ACCESS_TOKEN}`,
    },
  });
}
