import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ListBulletIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import { Badge, Spin, Select, Tag } from 'antd';
import moment from "moment";
import "moment/locale/it.js";
import { useEffect, useState, useRef } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import Chip from "../../components/atoms/Chip";
import Tabs from "../../components/atoms/Tabs";
import AppointmentCard from "../../components/molecules/AppointmentCard";
import PatientPastAppointment from "../../components/molecules/PatientPastAppointment";
import AppointmentCalendar from "../../components/organisms/AppointmentCalendar";
import Modal from "../../components/organisms/Modal";
import { useAppDispatch } from '../../customHooks/reduxHooks';
import { setInfo } from "../../store/slices/infoSlice";
import crossedCalendar from "../../theme/svg/crossed-calendar.svg";
import { trpc } from "../../utils/trpc";
import { getWeekNumber } from "../../utils/weekNumber";
import styles from "./PatientAppointments.module.scss";
import routes from "routes";
import { analyticsService } from "../../utils/analyticsService";
import { getAvailableDates } from "../../utils/avaliableDate";
import FormHeader from "../../components/atoms/FormHeader";
import { getNormalizedDate, toISOStingWithOffset } from "../../utils/dateTime";

interface Event {
  appointment: any;
  end: Date;
  start: Date;
  title: any;
}

interface Slot {
  action: "click" | "select" | "doubleClick";
  bounds: undefined;
  box: { x: number; y: number; clientX: number; clientY: number };
  end: Date;
  slots: Date[];
  start: Date;
}

interface Props {
  userInfo?: any;
  activeTab?: number;
  changedTab?: any;
}

export const PatientAppointments = ({userInfo, activeTab, changedTab}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;
  const blockRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const localizer = momentLocalizer(moment);

  const [preselectTime, setPreselectTime] = useState("");
  const [preselectDate, setPreselectDate] = useState<Date | undefined>(
    undefined
  );
  const [appointmentModalOpen, setAppointmentModalOpen] =
    useState<boolean>(false);
  const [informModalOpen, setInformModalOpen] =
    useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [appointmentVisibility, setAppointmentVisibility] = useState<
    "list" | "calendar"
  >("list");
  const [expiredAppointments, setExpiredAppointments] = useState<any[]>([]);
  const [yearFilter, setYearFilter] = useState(
    new Date().getFullYear().toString()
  );
  const [appointmentFilter, setAppointmentFilter] = useState<
    "today" | "tomorrow" | "thisWeek" | "nextWeek" | "all"
  >("today");
  const [events, setEvents] = useState<any[]>([]);
  const [allEvents, setAllEvents] = useState<any[]>([]);
  const [openMainTab, setOpenMainTab] = useState<"present" | "past">("present");
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [modalChildren, setModalChildren] = useState<any>(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [therapistsToggle, setTherapistToggle] = useState(true);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tabsItems, setTabsItems] = useState<any>([]);
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [scrollDown, setScrollDown] = useState(true);
  const [therapists, setTherapists] = useState([]);
  const [selectedTherapists, setSelectedTherapists] = useState<string[]>([]);
  const [modifyAppointment, setModifyAppointment] = useState<any>({});
  const [view, setView] = useState(Views.MONTH);
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/dashboard/appuntamenti', 'pageTitle': 'Appointments'});
  }, []);

  const { data: getAppointments, refetch: refetchAppointments } =
    trpc.mainService.appointment.getAppointments.useQuery({});

  const getMyTherapistFreeDates = trpc.mainService.patient.getTherapistAvailability.useQuery({therapistId: userInfo?.id},
    {enabled: true}
  );

  const updateOfflineAppointments =
    trpc.mainService.offlineAppointments.updateOfflineAppointments.useMutation();

  const getOfflineAppointments =
    trpc.mainService.offlineAppointments.getOfflineAppointments.useQuery(
      undefined,
      {enabled: userInfo?.role === 'therapist'}
    );


  const tagRender = (props: any) => {
    const {label, value, closable, onClose} = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const therapist: any = therapists?.find((t: any) => t.id === value);
    return (
      <Tag
        color={therapist?.therapyType === 'psychiatry' ? '#CDF5EF' :
          therapist?.therapyType === 'single' ? '#DFEAFF' :
            therapist?.therapyType === 'couple' ? '#E0D9FD' :
              therapist?.therapyType === 'underage' ? '#E7F5FF' :
                undefined}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        bordered
        style={{
          marginInlineEnd: 8,
          display: 'flex',
          borderColor: therapist?.therapyType === 'psychiatry' ? '#4BCAB1' :
            therapist?.therapyType === 'single' ? '#2254A4' :
              therapist?.therapyType === 'couple' ? '#5B4CAA' :
                therapist?.therapyType === 'underage' ? '#72B4EE' :
                  undefined,
          color: therapist?.therapyType === 'psychiatry' ? '#4BCAB1' :
            therapist?.therapyType === 'single' ? '#2254A4' :
              therapist?.therapyType === 'couple' ? '#5B4CAA' :
                therapist?.therapyType === 'underage' ? '#72B4EE' :
                  undefined,
          fontWeight: 500,
        }}
      >{label}
      </Tag>
    );
  };

  const dropdownOptions = therapists.map((therapist: any) => ({
    value: therapist.id,
    label: (
      <div
        className={`custom-tag ${
          therapist.therapyType === 'psychiatry'
            ? 'psychiatry'
            : therapist.therapyType === 'single'
              ? 'single'
              : therapist.therapyType === 'couple'
                ? 'couple'
                : therapist.therapyType === 'underage'
                  ? 'underage'
                  : ''
        }`}
      >
        {`${therapist.givenName} ${therapist.familyName}`}
      </div>
    ),
  }));

  const setModal = (date: any, time: any) => {
    setModalChildren(
      <>
        <FormHeader
          title="Appuntamento confermato"
          closeModal={() => {
            setModalIsVisible(false);
            setModalChildren(undefined);
          }}
          closeButton
        ></FormHeader>
        Appuntamento del {date} alle ore {time} è stato confermato con
        successo
      </>
    );
    setModalIsVisible(true);
  }

  const createOfflineApp = async () => {
    const now = +getNormalizedDate(new Date());
    setIsSubmitting(true);
    const dates = selectedDates.map(appointment => {
      return ({
        dateTime: toISOStingWithOffset(appointment.dateTime),
        occurrence: appointment?.occurrence
      })
    });
    const filteredDates = dates.filter(date => +getNormalizedDate(new Date(date.dateTime)) > now);
    await updateOfflineAppointments.mutateAsync(
      {
        offlineAppointment: filteredDates,
        operation: 'update'
      },
    ).then(async () => {
      const therapistFreeDates = await getMyTherapistFreeDates.refetch();
      const offlineAppointments = await getOfflineAppointments.refetch()
      if (therapistFreeDates.status === "success" && offlineAppointments.status === "success") {
        setTherapistToggle(true)
        dispatch(
          setInfo({text: "Dati aggiornati con successo", variant: "confirm"})
        );
      } else if (therapistFreeDates.status === "error" || offlineAppointments.status === "error") {
        dispatch(
          setInfo({
            variant: "error",
            text: "Si è verificato un errore",
          })
        )
      }
    });
  }

  const handleChangeTabIndex = (index: number) => {
    setTabIndex(index)
  }

  useEffect(() => {
    const currentBlock = blockRef.current;
    if (currentBlock) {
      currentBlock.addEventListener('scroll', handleManualScroll);
    }
    return () => {
      if (currentBlock) {
        currentBlock.removeEventListener('scroll', handleManualScroll);
      }
    };
  }, [tabIndex, activeTab]);

  const handleManualScroll = () => {
    if (blockRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = blockRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
      const isAtTop = scrollTop === 0;

      if (isAtBottom) {
        setScrollDown(false);
      } else if (isAtTop) {
        setScrollDown(true);
      }
    }
  };

  const handleScroll = () => {
    if (blockRef.current) {
      const {clientHeight, scrollHeight, scrollTop} = blockRef.current;
      if (scrollDown) {
        const newScrollTop = scrollTop + window.innerHeight;
        if (newScrollTop + clientHeight >= scrollHeight) {
          setScrollDown(false);
        }
        blockRef.current.scrollTo({
          top: newScrollTop,
          behavior: 'smooth'
        });
      } else {
        blockRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        setScrollDown(true);
      }
    }
    return
  };

  const handleDateClick = (dateTimeString: any, available: any, offline: any) => {
    if (!available && !offline) return;
    const existingIndex = selectedDates.findIndex(date => +date.dateTime === +dateTimeString);

    if (existingIndex !== -1) {
      const newSelectedDates = selectedDates.filter(date => +date.dateTime !== +dateTimeString);
      setSelectedDates(newSelectedDates);
      setIsSubmitting(false);
    } else {
      const newSelectedDates = [...selectedDates, {dateTime: dateTimeString, occurrence: 'once'}];
      setSelectedDates(newSelectedDates);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (userInfo?.role === "therapist") {
      if (therapistsToggle) {
        if (getMyTherapistFreeDates?.status === "loading" && getOfflineAppointments?.status === "loading") {
          setLoadingToggle(true);
        } else if (
          getMyTherapistFreeDates?.status === "success" && getOfflineAppointments?.status === "success"
        ) {
          setLoadingToggle(false);
          setTherapistToggle(false);
          const therapistFreeDates: any = getMyTherapistFreeDates.data;
          setAvailableDates(
            getAvailableDates(
              therapistFreeDates?.daySlots,
              therapistFreeDates?.timeSlots,
              therapistFreeDates?.appointmentsBusyTime,
              therapistFreeDates?.offlineAppointments
            ))

          const offlineAppointments: any = getOfflineAppointments.data;
          const initialSelectedDates = offlineAppointments.offline.map(appointment => ({
            dateTime: getNormalizedDate(appointment.dateTime),
            occurrence: appointment?.occurrence
          }));
          setSelectedDates(initialSelectedDates);
          setLoading(false)
        }
      }
    }
  }, [
    therapistsToggle,
    getMyTherapistFreeDates?.status,
    getOfflineAppointments?.status
  ]);

  const emptyState = (
    <div className={styles.emptyState}>
      <div className={styles.iconWrapper}>
        <img src={crossedCalendar} alt="crossed calendar" />
      </div>
      <div>
        Non hai appuntamenti in programma.{" "}
        <span>
          {userInfo?.role === "patient" &&
            "Scrivi al tuo terapeuta per organizzarne uno."}
        </span>
      </div>
      <Button
        className={styles.emptyStateBtn}
        onClick={() => navigate(routes.Chat.path)}
        variant="secondary"
      >
        Vai alla chat
      </Button>
    </div>
  );

  const therapyType = userInfo.role === 'therapist' ? userInfo?.therapies?.includes('psychiatry') : userInfo?.therapyType === "psychiatry";

  const selectDate = (event: Event | any, modify: boolean) => {
    setSelectedAppointment(
      <AppointmentCard
        fromCalendar
        className={`${styles.selectedAppointmentCard} ${styles[event.appointment.therapyType]}`}
        appointment={event.appointment}
        role={userInfo?.role!}
        isPsychiatry={therapyType}
        closeCard={() => setSelectedAppointment(null)}
        setModalChildren={setModalChildren}
        setModalIsVisible={setModalIsVisible}
        modifyAppointment={modify}
      />
    );
  };

  const selectSlot = (slot: Slot) => {
    const today = +getNormalizedDate(new Date().toDateString());
    const slotDay = +getNormalizedDate(slot.start);
    const slotTime = new Date(slotDay).toLocaleTimeString("it", {
      hour: "2-digit",
      minute: '2-digit'
    });

    if (today > slotDay) {
      dispatch(
        setInfo({
          text: "Data antecedente ad oggi",
          variant: "error",
          title: "Data non disponibile",
        })
      );
    } else {
      view === 'week' && setPreselectTime(slotTime);
      setPreselectDate(slot.start);
      setAppointmentModalOpen(true);
    }
  };

  const handleTherapistChange = (value: string[]) => {
    setSelectedTherapists(value);

    const filteredEvents = allEvents.filter((event) =>
      value.includes(event.appointment.therapist.id)
    );

    setEvents(filteredEvents);
  };

  useEffect(() => {
    setTabsItems([]);
    if (getAppointments) {
      setAppointments(getAppointments.appointments);
      const today = +getNormalizedDate();
      const now = +getNormalizedDate();
      const tomorrow = today + 1000 * 60 * 60 * 24;
      const nextWeek = getWeekNumber(today) + 1;
      const thisWeek = getWeekNumber(today);

      const uniqueTherapists: any = Array.from(
        new Map(
          getAppointments.appointments.filter((appointment) => appointment.activeChat === true).map((appointment) => [
            appointment.therapist.id,
            {
              id: appointment.therapist.id,
              familyName: appointment.therapist.family_name,
              givenName: appointment.therapist.given_name,
              therapyType: appointment.therapyType,
            },
          ])
        ).values()
      );
      setTherapists(uniqueTherapists);

      setSelectedTherapists(uniqueTherapists.map((t) => t.id));

      const initialEvents = getAppointments.appointments
        .filter((a) => a.status !== 'cancelled')
        .filter((a) => a.status !== 'moved')
        .map((i, index) => ({
          appointment: i,
          title: (
            <div className="customEvents" key={index}>
                <span className="eventDate">
                  <Badge color={i.therapyType === 'psychiatry' ? '#4BCAB1' :
                    i.therapyType === 'single' ? '#2254A4' :
                      i.therapyType === 'couple' ? '#5B4CAA' :
                        i.therapyType === 'underage' ? '#72B4EE' :
                          undefined}/>
                  <span>
                    {getNormalizedDate(i.startTime).toLocaleTimeString("it", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </span>
              <div className="patientNameContainer">
                <span>{userInfo?.role === 'therapist' ? i.patient?.given_name : i.therapist?.given_name}</span>{' '}
                <span>{userInfo?.role === 'therapist' ? i.patient?.family_name : i.therapist?.family_name}</span>{' '}
                {!i.patient && 'Paziente Eliminato'}
              </div>
            </div>
          ),
          start: getNormalizedDate(i.startTime),
          end: getNormalizedDate(i.endTime),
        }))

      setAllEvents(initialEvents);
      setEvents(initialEvents);

      if (userInfo?.role === "patient") {
        setAppointments(
          getAppointments.appointments
            .filter((a) => a.status !== "cancelled")
            .filter((a) => a.status !== "completed")
            .filter((a) => a.status !== "expired")
            .filter((a) => a.status !== "moved")
        );
      } else if (userInfo?.role === "therapist") {
        setAppointments(
          getAppointments.appointments
            .filter((a) => {
              const appointmentDate = +getNormalizedDate(new Date(a.startTime));

              if (appointmentFilter === "today") {
                return (
                  appointmentDate >= today && appointmentDate < tomorrow &&
                  appointmentDate > now
                );
              }

              if (appointmentFilter === "tomorrow") {
                return (
                  appointmentDate >= tomorrow &&
                  appointmentDate < tomorrow + 1000 * 60 * 60 * 24
                );
              }

              if (appointmentFilter === "thisWeek") {
                return (
                  getWeekNumber(appointmentDate) === thisWeek &&
                  appointmentDate >= today &&
                  appointmentDate > now
                );
              }

              if (appointmentFilter === "nextWeek") {
                return getWeekNumber(appointmentDate) === nextWeek;
              }

              return true;
            })
            .filter((a) => a.status !== "cancelled")
            .filter((a) => a.status !== "completed")
            .filter((a) => a.status !== "expired")
            .filter((a) => a.status !== "moved")
        );
      }
      if (userInfo?.role === "patient") {
        setExpiredAppointments(
          getAppointments.appointments.filter(
            (a) => getNormalizedDate(new Date(a.endTime)).getTime() < today
          )
        );
      } else if (userInfo?.role === "therapist") {
        setExpiredAppointments(
          getAppointments.appointments.filter(
            (a) =>
              (getNormalizedDate(new Date(a.endTime)).getTime() < today && a.status !== "payed_moved") ||
              a.status === "moved" ||
              a.status === "cancelled"
          )
        );
      }
    }
  }, [getAppointments, appointmentFilter]);


  useEffect(() => {
    setTabsItems([
      {
        key: 1,
        label: "Programmati",
        active: openMainTab === "present",
        content: (
          <div className={styles.bottomPart}>
            {userInfo?.role === "therapist" && (
              <div className={styles.chipsWrapper}>
                <Chip
                  onClick={() => {
                    setAppointmentFilter("today");
                  }}
                  size="medium"
                  active={appointmentFilter === "today"}
                >
                  Oggi
                </Chip>
                <Chip
                  onClick={() => {
                    setAppointmentFilter("tomorrow");
                  }}
                  active={appointmentFilter === "tomorrow"}
                  size="medium"
                >
                  Domani
                </Chip>
                <Chip
                  onClick={() => {
                    setAppointmentFilter("thisWeek");
                  }}
                  active={appointmentFilter === "thisWeek"}
                  size="medium"
                >
                  Questa settimana
                </Chip>
                <Chip
                  onClick={() => {
                    setAppointmentFilter("nextWeek");
                  }}
                  active={appointmentFilter === "nextWeek"}
                  size="medium"
                >
                  Prossima settimana
                </Chip>
                <Chip
                  onClick={() => {
                    setAppointmentFilter("all");
                  }}
                  active={appointmentFilter === "all"}
                  size="medium"
                >
                  Tutti
                </Chip>
              </div>
            )}
            {appointments?.length > 0 ? (
              <>
                <div className={styles.nextAppointment}>
                  <h5 className={styles.appointmentsSection}>
                    Prossima seduta
                  </h5>
                  <AppointmentCard
                    className={styles[appointments[0]?.therapyType]}
                    appointment={appointments[0]}
                    role={userInfo?.role!}
                    isPsychiatry={therapyType}
                    setModalChildren={setModalChildren}
                    setModalIsVisible={setModalIsVisible}
                    setModal={setModal}
                  />
                </div>

                {appointments.some((i, index) => {
                  const startTime = getNormalizedDate(i.startTime);
                  const today = getNormalizedDate(new Date());
                  return index !== 0 && startTime > today;
                }) && (
                  <>
                    <h5 className={styles.appointmentsSection}>
                      Sedute programmate
                    </h5>
                    <div className={styles.programmedAppointment}>
                      {appointments.map((i, index) => {
                        const startTime = getNormalizedDate(i.startTime);
                        const today = getNormalizedDate(new Date());
                        if (index !== 0 && startTime > today) {
                          return (
                            <div key={index}>
                              <AppointmentCard
                                className={styles[i.therapyType]}
                                appointment={i}
                                role={userInfo?.role!}
                                isPsychiatry={therapyType}
                                setModalChildren={setModalChildren}
                                setModalIsVisible={setModalIsVisible}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className={styles.emptyStateContainer}>{emptyState}</div>
            )}
          </div>
        ),
      },
      {
        key: 2,
        label: "Passati",
        active: openMainTab === "past",
        content: (
          <div className={styles.bottomPart}>
            {expiredAppointments?.length > 0 ? (
              userInfo?.role === "therapist" ? (
                <div className={styles.programmedAppointment}>
                  {expiredAppointments
                    .map((a, index) => {
                      const appointmentDate = new Date(
                        a.startTime
                      ).toLocaleDateString("it", {
                        weekday: "short",
                        day: "2-digit",
                        month: "long",
                      });

                      const appointmentHour = `${getNormalizedDate(
                        a.startTime
                      ).toLocaleTimeString("it", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })} - ${getNormalizedDate(a.endTime).toLocaleTimeString("it", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}`;

                      let appointmentStatus;

                      switch (a.status) {
                        case "expired":
                          appointmentStatus = "Cancellato";
                          break;
                        case "cancelled":
                          appointmentStatus = "Cancellato";
                          break;
                        case "completed":
                          appointmentStatus = "Completato";
                          break;
                        case "moved":
                          appointmentStatus = "Spostato";
                          break;
                        case 'payed_moved':
                          appointmentStatus = 'payed_moved (!!!)';
                          break;
                        case "ready":
                          appointmentStatus = "Completato";
                          break;
                        case "waiting_for_confirm":
                          appointmentStatus = "Cancellato";
                          break;
                        default:
                          appointmentStatus = a.status;
                      }

                      return (
                        <div
                          key={index}
                          className={styles.pastAppointmentTherapist}
                        >
                          <div className={styles[a.status]}>
                            <InfoCircleOutlined /> {appointmentStatus}
                          </div>
                          <div className={styles.patientName}>
                            {a?.patient?.given_name} {a?.patient?.family_name}{" "}
                            {!a.patient && "Paziente Eliminato"}
                          </div>
                          <div className={styles.appointmentDetail}>
                            <div>
                              {appointmentDate} | {appointmentHour}
                            </div>

                            <div className={styles[a.status]}>
                              <InfoCircleOutlined /> {appointmentStatus}
                            </div>
                          </div>
                        </div>
                      );
                    })
                    .reverse()}
                </div>
              ) : (
                userInfo?.role === "patient" && (
                  <div className={styles.programmedAppointment}>
                    <div className={styles.pastAppointmentHeader}>
                      <select
                        className={styles.select}
                        onChange={(e) => {
                          setYearFilter(e.target.value);
                          setOpenMainTab("past");
                        }}
                        value={yearFilter}
                      >
                        {new Array(new Date().getFullYear() - 2023)
                          .fill(null)
                          .map((value, index) => (
                            <option
                              value={new Date().getFullYear() - index}
                              key={index}
                            >
                              {new Date().getFullYear() - index}
                            </option>
                          ))}
                      </select>
                      {/* <Button variant="secondary">Scarica tutte le fatture</Button> TODO: nascondere in prima fase */}
                    </div>
                    {expiredAppointments
                      .filter(
                        (i) =>
                          new Date(i.startTime).getFullYear().toString() ===
                            yearFilter &&
                          (i.status === "completed" || i.status === "ready")
                      )
                      .map((i, index) => {
                        const therapist = `${i.therapist.given_name} ${i.therapist.family_name}`;
                        return (
                          <PatientPastAppointment
                            key={index}
                            invoiceDate={i.startTime}
                            appointmentDate={i.startTime}
                            therapist={therapist}
                            price={i.amountPaid}
                          />
                        );
                      })
                      .reverse()}
                  </div>
                )
              )
            ) : (
              <div className={styles.emptyStateContainer}>{emptyState}</div>
            )}
          </div>
        ),
      },
      userInfo?.role === "therapist" && {
        key: 3,
        label: "Slot Occupati",
        active: openMainTab === "past",
        content: (
          loading ? <Modal>
              <div className={styles.loadingSpinner}>
                <Spin
                  className={styles.spinner}
                  indicator={
                    <LoadingOutlined
                      color={"#3576bb"}
                      style={{fontSize: 32}}
                      spin
                    />
                  }
                />
              </div>
            </Modal> :
            <div ref={blockRef} className={`${styles.bottomPart}  ${styles.appointment}`}>
              <div className={styles.appointmentWrapper}>
                <div className={styles.appointmentContainer}>
                  <div className={styles.appointmentHeader}>
                    <p>Calendario</p>
                    <span>Seleziona gli slot che NON potranno essere prenotati dai clienti:</span>
                  </div>
                  <div className={styles.legendWrapper}>
                    <div className={styles.statusText}>
                      <span className={`${styles.circle} ${styles.gray}`}></span>
                      <span>non disponibile</span>
                    </div>
                    <div className={styles.statusText}>
                      <span className={`${styles.circle} ${styles.white}`}></span>
                      <span>disponibile</span>
                    </div>
                    <div className={styles.statusText}>
                      <span className={`${styles.circle} ${styles.red}`}></span>
                      <span>occupato</span>
                    </div>
                  </div>
                  <div className={styles.appointmentBlock}>
                    {availableDates.map((appointment: any, index: number) => (
                      <div key={index} className={styles.dateCard}>
                        <div className={styles.dateCardTitle}>
                          {new Date(appointment.day).toLocaleDateString('it-IT', {weekday: 'long'})}
                          <span>
                            <div>{new Date(appointment.day).toLocaleDateString('it-IT', {
                               day: '2-digit',
                               month: 'long'
                             })}
                            </div>
                          </span>
                        </div>
                        <div className={styles.slotsContainer}>
                          {appointment.availableTime.map((freeTime, timeIndex) => {
                            const dateTimeString = new Date(`${appointment.day} ${freeTime.time}`);
                            const isSelected = selectedDates.some(date => +getNormalizedDate(date.dateTime) === +getNormalizedDate(dateTimeString));
                            return (
                              <div
                                key={timeIndex}
                                className={`${styles.slot} ${isSelected ? styles.selected : ''} ${!freeTime.available && !freeTime.offline ? styles.disabled : ''}  ${!freeTime.available && freeTime.offline && isSelected ? styles.booked : ''}`}
                                onClick={() => handleDateClick(dateTimeString, freeTime.available, freeTime.offline)}
                              >
                                {freeTime.time}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.appointmentButtonWrapper}>
                    <Button className={styles.appointmentButton}
                            variant='primary'
                            disabled={isSubmitting}
                            onClick={() => createOfflineApp()}>
                      Conferma
                    </Button>
                  </div>
                </div>
              </div>
              <button onClick={handleScroll} className={styles.scrollButton}>
                {scrollDown ? <ChevronDownIcon/> : <ChevronUpIcon/>}
              </button>
            </div>
        ),
      },
    ]);
  }, [appointments, loading, selectedDates, scrollDown]);

  const handleViewChange = (newView: string) => {
    setView(newView);
    setPreselectTime('');
    setPreselectDate(undefined);
  };

  return (
    <div className={styles.patientAppointments}>
      {modalIsVisible &&
        <Modal modal close={() => {
          setModalIsVisible(false);
        }}>
          {modalChildren}
        </Modal>
      }
      {appointmentModalOpen && (
        <Modal modal close={() => {
          setAppointmentModalOpen(false);
        }}>
          <AppointmentCalendar
            closeModal={() => setAppointmentModalOpen(false)}
            openInformModal={(e: any) => {
              setModifyAppointment(e)
              setInformModalOpen(true)
            }}
            chat=""
            preselectDay={preselectDate}
            preselectHour={preselectTime}
          />
        </Modal>
      )}
      {informModalOpen && (
        <Modal modal close={() => {
          setInformModalOpen(false);
        }}>
          <>
            <div>
              {therapyType === 'psychiatry' ?
                'Non è possibile creare un’altra sessione, hai già programmato la prima sessione con questo paziente, puoi aggiornare quella.' :
                'Non è possibile creare un’altra sessione, hai già una sessione gratuita programmata con questo paziente, puoi aggiornare quella.'
              }
            </div>
            <div className={styles.firstAppointmentButtons}>
              <Button className={styles.space} onClick={() => {
                setInformModalOpen(false)
                setAppointmentModalOpen(true)
              }}>
                Annulla
              </Button>
              <Button onClick={() => {
                setInformModalOpen(false);
                selectDate({appointment: modifyAppointment}, true)
              }}>
                Sposta
              </Button>
            </div>
          </>
        </Modal>
      )}
      <div className={styles.patientAppointmentHeader}>
        <h4>Appuntamenti</h4>
            <Tabs
              className={styles.therapistTab}
              activeLabelClassName={styles.customActiveLabel}
              labelClassName={styles.customLabelWrapper}
              changedTab={changedTab}
              items={[
                {
                  key: 1,
                  active: activeTab === 1,
                  label: (
                    <div
                      className={styles.tabLabel}
                    >
                      <div className={styles.icon}>
                        <ListBulletIcon />
                      </div>
                      <span>Lista</span>
                    </div>
                  ),
                  content: <div></div>,
                },
                {
                  key: 2,
                  active: activeTab === 2,
                  label: (
                    <div
                      className={styles.tabLabel}
                    >
                      <div className={styles.icon}>
                        <CalendarIcon />
                      </div>
                      <span>Calendario</span>
                    </div>
                  ),
                  content: <div></div>,
                },
              ]}
            ></Tabs>
      </div>
      {activeTab === 1 ? (
          <Tabs
            labelClassName={styles.tabsLabel}
            className={styles.tabs}
            activeClassName="patientAppointments"
            changedTab={handleChangeTabIndex}
            items={getAppointments
              ? (userInfo?.role === "therapist" && tabsItems) ||
              (userInfo?.role === "patient" && tabsItems.filter(item => item.key !== 3 && item)) : []}
            indexKey={state?.key}
          />
      ) : (
        <div className={styles.calendarWrapper}>
          {(userInfo?.role === 'patient' && therapists.length > 1) && (
            <Select
              popupClassName="customSelect"
              mode="multiple"
              showSearch={false}
              tagRender={tagRender}
              style={{width: '100%', marginBottom: '1.25rem'}}
              options={dropdownOptions}
              onChange={handleTherapistChange}
              defaultValue={selectedTherapists}
              placeholder="Scegliere il terapeuta"
            />
          )}
        <Calendar
          className={styles.customCalendar}
          messages={{
            previous: (
              <div className="icon">
                <ChevronLeftIcon />
              </div>
            ),
            next: (
              <div className="icon">
                <ChevronRightIcon />
              </div>
            ),
            month: "Mese",
            week: "Settimana",
            day: "Giorno",
            today: "Oggi",
          }}
          view={view}
          onView={handleViewChange}
          localizer={localizer}
          views={{ day: false, month: true, week: true }}
          events={events}
          tooltipAccessor={() => ''}
          min={new Date(0, 0, 0, 8, 0, 0)}
          max={new Date(0, 0, 0, 23, 0, 0)}
          onSelectEvent={(e) => {
            selectDate(e, false);
          }}
          selectable
          onSelectSlot={(s) => {
            userInfo?.role === 'therapist' && selectSlot(s);
          }}
          eventPropGetter={(events) => {
            const backgroundColor =
              events.appointment.therapyType === 'psychiatry' ? '#CDF5EF' :
                events.appointment.therapyType === 'single' ? '#DFEAFF' :
                  events.appointment.therapyType === 'couple' ? '#E0D9FD' :
                    events.appointment.therapyType === 'underage' ? '#E7F5FF' :
                      undefined;
            const color = events.appointment.therapyType === 'psychiatry' ? '#4BCAB1' :
              events.appointment.therapyType === 'single' ? '#2254A4' :
                events.appointment.therapyType === 'couple' ? '#5B4CAA' :
                  events.appointment.therapyType === 'underage' ? '#72B4EE' :
                    undefined;
            const borderLeft = events.appointment.therapyType === 'psychiatry' ? '0.3125rem solid #4BCAB1' :
              events.appointment.therapyType === 'single' ? '0.3125rem solid #2254A4' :
                events.appointment.therapyType === 'couple' ? '0.3125rem solid #5B4CAA' :
                  events.appointment.therapyType === 'underage' ? '0.3125rem solid #72B4EE' :
                    undefined;
            return {style: {backgroundColor, color, borderLeft}}
          }}
        />
        </div>
      )}
      <div className={styles.selectedAppointment}>{selectedAppointment}</div>
    </div>
  );
};
