import { InfoCircleOutlined } from "@ant-design/icons";
import { DayPicker } from "react-day-picker";
import Button from "../../atoms/Button";
import FormHeader from "../../atoms/FormHeader";
import styles from "./AppointmentCalendar.module.scss";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { it } from "date-fns/locale";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useRouteLoaderData } from "react-router-dom";
import { Socket } from "socket.io-client";
import { hoursSlot } from "../../../utils/hoursSlot";
import { trpc } from "../../../utils/trpc";
import Input from "../../atoms/Input";
import routes from "routes";
import { time } from "utils/general";
import { setInfo } from "store/slices/infoSlice";
import { useAppDispatch } from "customHooks/reduxHooks";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import { getNormalizedDate, getNormalizedTime } from "../../../utils/dateTime";
import { useTherapistData } from '../../../customHooks/therapistDataContext';

interface Props {
  closeModal: () => void;
  openInformModal?: (e: any) => void;
  firstAppointment?: boolean;
  subtitle?: string | ReactNode;
  patientId?: string;
  chat: any;
  preselectDay?: Date;
  preselectHour?: string;
  insideForm?: boolean;
  appointmentToModify?: any;
  onMove?: () => void;
}

export const AppointmentCalendar = ({
  closeModal,
  openInformModal,
  firstAppointment,
  subtitle,
  patientId,
  chat,
  preselectDay,
  preselectHour,
  insideForm,
  appointmentToModify,
  onMove,
}: Props) => {
  const socket = useRouteLoaderData("root") as Socket;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {therapistData} = useTherapistData();

  const [selectedDay, setSelectedDay] = useState<any[]>(
    preselectDay
      ? [{ date: new Date(preselectDay), endTime: new Date(preselectDay) }]
      : [{ date: new Date(), endTime: new Date() }]
  );
  const [selectedChat, setSelectedChat] = useState<string | undefined>(chat?.id);
  const [selectedHour, setSelectedHour] = useState(
    preselectHour ? preselectHour : ""
  );
  const [busyDay, setBusyDay] = useState<any[]>([]);
  const [hoursAppointment, setHoursAppointment] = useState<string[]>([]);
  const [showGoDownArrow, setShowGoDownArrow] = useState(true);
  const [patientOptions, setPatientOptions] = useState<any>();
  const [selectedPatient, setSelectedPatient] = useState<string | undefined>(
    patientId
  );
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [showHourDate, setShowHourDate] = useState(false);

  const myChats = trpc.mainService.chat.getChats.useQuery().data;

  const patient = trpc.mainService.therapist.getPatient.useQuery(
    {patientId: patientId!, accountTherapyType: chat?.therapyType, therapistId: chat?.therapist?.id},
    {enabled: !!patientId,}
  );

  const getPreferences =
    trpc.mainService.therapist.getPreferences.useQuery().data;

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {}
  );

  const createAppointment =
    trpc.mainService.appointment.createAppointment.useMutation();

  const createWidgetMessage =
    trpc.mainService.appointment.createWidgetMessage.useMutation();

  const deleteAppointment = trpc.mainService.appointment.deleteAppointment.useMutation();

  const myPatient =
    trpc.mainService.therapist.getMyPatients.useQuery().data?.patients;

  const modifyAppointment =
    trpc.mainService.appointment.moveAppointment.useMutation();

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined});

  const selectDay = (day: Date) => {
    const daysWithAppointment = busyDay
      ?.filter((a) => a.date.toDateString() === day.toDateString())
      .filter((a) => a.status !== "moved")
      .filter((a) => a.status !== "cancelled");

    if (daysWithAppointment?.length > 0) {
      setSelectedDay(daysWithAppointment);
    } else {
      setSelectedDay([{ date: day, endTime: day, startTime: null }]);
    }
  };

  const selectHour = async () => {
    const day = selectedDay[0].date.toDateString();
    const date = getNormalizedTime(new Date(`${day} ${selectedHour}:00`));

    if (selectedHour !== "") {
      setOpenLoadingModal(true);
      try {
        const { appointment } = await createAppointment.mutateAsync({
          startTime: date,
          patientId: selectedPatient!,
          therapistId: getCurrentUserInfoResponse.data?.user?.id!,
          accountTherapyType: chat?.therapyType
        });
        await sendAppointmentWidget(appointment, 'create');
        getAppointments?.refetch();

        if (patient?.data?.patient.isFirstAppointment) {
          analyticsService({ 'event': 'free_session_booked' });
        }
      } catch (error: any) {
        raiseException(error);
        if (error?.shape?.errorCode === "AppointmentInvalidStartTime") {
          dispatch(
            setInfo({
              text: "L'ora di inizio selezionata è troppo vicina",
              variant: "error",
            })
          );
        } else {
          dispatch(setInfo({ text: "Errore inaspettato", variant: "error" }));
        }
      } finally {
        setOpenLoadingModal(false);
      }
    }
  };

  const onModifyAppointment = async () => {
    const day = selectedDay[0].date.toDateString();
    const startTime = getNormalizedTime(new Date(`${day} ${selectedHour}:00`));

    if (selectedHour !== "") {
      setOpenLoadingModal(true);
      try {
        const { appointment } = await modifyAppointment.mutateAsync({startTime, appointmentId: appointmentToModify.id!, therapyType: chat?.therapyType!});
        await sendAppointmentWidget(appointment, 'modify');
        getAppointments?.refetch();
      } catch (error: any) {
        raiseException(error);
        console.error("ERROR:", error?.shape?.message);
      } finally {
        setOpenLoadingModal(false);
      }
    }
  };

  const sendAppointmentWidget = async (appointment: any, type: string) => {
    try {
      if (selectedChat && appointment?.id) {
        const widget: any = await createWidgetMessage.mutateAsync({
          chatId: selectedChat,
          type: "widget",
          appointmentId: appointment?.id
        });

        if (widget?.appointmentData?.id) {
          socket.emit(
            "sendWidgetMessage",
            widget?.newMessage,
            widget?.appointmentData,
            widget?.chat
          );
          if (type === 'modify') {
            if (onMove) {
              onMove();
            }
          }
          closeModal();
        }
      }
    } catch (error: any) {
      try {
        await deleteAppointment.mutateAsync({appointmentId: appointment?.id});
      } catch {}
        dispatch(
          setInfo({
            text: "Si è verificato un errore, prova a creare nuovamente l'appuntamento.",
            variant: "error",
          })
        );
        closeModal();
    }
  }

  useEffect(() => {
    if (busyDay && selectedDay) {
      selectDay(selectedDay[0].date);
    }
  }, [busyDay]);

  useEffect(() => {
    if (myChats) {
      const patientChat = myChats.chats.find(
        (c) => c.patient?.id === selectedPatient
      );
      setSelectedChat(patientChat?.id);
    }
  }, [myChats, selectedPatient]);

  useEffect(() => {
    if (getAppointments?.data) {
      const appointmentsDate = getAppointments?.data?.appointments
        .filter(
          (a) =>
            (a.status === "ready" || a.status === "waiting_for_confirm") &&
            a.id !== appointmentToModify?.id
        )
        .map((a) => {
          return {
            date: getNormalizedDate(a.startTime),
            startTime: getNormalizedDate(a.startTime),
            endTime: getNormalizedDate(a.endTime),
            patient: `${a.patient?.given_name} ${a.patient?.family_name}`,
            status: a.status,
          }});
      setBusyDay(appointmentsDate);
    }
  }, [getAppointments?.data]);

  useEffect(() => {
    if (getPreferences) {
      setHoursAppointment(
        hoursSlot.map((i) => i.hours).reduce((a, b) => a.concat(b))
      );
    }
  }, [getPreferences]);

  useEffect(() => {
    if (myPatient) {
      setPatientOptions(
        [{ name: "Seleziona un paziente", value: "" }].concat(
          myPatient?.map((p) => ({
            name: `${p.given_name} ${p.family_name}`,
            value: p.id,
          }))
        )
      );
      // if (patientId === undefined) {
      //   setSelectedPatient(myPatient[0].id);
      // }
    }
  }, [myPatient]);

  // useEffect(() => {
  //   if (appointmentToModify) {
  //     setSelectedDay([{ date: new Date(appointmentToModify.startTime) }]);
  //   }
  // }, [appointmentToModify]);

  const patientSelect = async (patientId: string) => {
    const appointmentStatus = ['ready', 'waiting_for_confirm', 'payed_to_be_confirmed', 'payed_moved'];
    const notCompletedFirstAppointments = getAppointments?.data?.appointments.filter(el => {
      if (el.patient?.id === patientId && el.isFirstAppointment && appointmentStatus.includes(el.status)) {
          return el;
      }
    });
    if (notCompletedFirstAppointments?.length) {
      closeModal();
      if (openInformModal) {
        openInformModal(notCompletedFirstAppointments[0]);
      }
    } else {
      setSelectedPatient(patientId);
    }
  }

  return (
    <>
      {!insideForm && (
        <FormHeader
          title="Seleziona uno slot da inviare al paziente"
          closeModal={closeModal}
          closeButton
        />
      )}
      {location.pathname === routes.Appointments.path && !insideForm && (
        <Input
          select
          options={patientOptions}
          onChange={(e) => {
            patientSelect(e.target.value)
          }}
          value={selectedPatient}
        />
      )}
      {(selectedPatient || patientId) && (
        <div className={styles.bottomPart}>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          <div className={styles.calendarContainer}>
            <div
              className={`${styles.dateContainer} ${
                showHourDate && styles.hideContainer
              }`}
            >
              <DayPicker
                locale={it}
                mode="multiple"
                selected={selectedDay.map((d) => d.date)}
                disabled={(date) => {
                  const startOfTheDay = new Date();
                  const calendarDate = new Date(date);
                  startOfTheDay.setHours(0);
                  calendarDate.setHours(0);
                  startOfTheDay.setMinutes(0);
                  calendarDate.setMinutes(0);
                  startOfTheDay.setSeconds(0);
                  calendarDate.setSeconds(0);
                  startOfTheDay.setMilliseconds(0);
                  calendarDate.setMilliseconds(0);
                  return +getNormalizedDate(date) < +getNormalizedDate(startOfTheDay);
                }}
                defaultMonth={selectedDay.length > 0 ? new Date(selectedDay[0].date) : new Date()}
                onDayClick={(date) => {
                  selectDay(date);
                  setShowHourDate(true);
                }}
              />
            </div>
            <div
              className={`${styles.hoursContainer} ${
                !showHourDate && styles.hideContainer
              }`}
            >
              <div className={styles.selectedDay}>
                {selectedDay[0].date.toLocaleDateString("it", {
                  day: "2-digit",
                  weekday: "long",
                  month: "long",
                })}
              </div>
              <div
                className={styles.hoursWrapper}
                onScroll={(e) => {
                  setShowGoDownArrow(
                    e.currentTarget.scrollTop !==
                      e.currentTarget.scrollHeight -
                        e.currentTarget.offsetHeight
                  );
                }}
              >
                {hoursAppointment.map((h) => {
                  const day = new Date(
                    `${selectedDay[0].date.toDateString()} ${h}`
                  );
                  const hour = `${h.split(":")[0]}:${h.split(":")[1]}`;
                  const patientName = selectedDay.find(
                    (d) => d.date.toTimeString().split(" ")[0] === h
                  )?.patient;

                  return selectedDay
                    .filter((d) => d.status !== "moved")
                    .filter((d) => d.status !== "cancelled")
                    .find((d) => {
                      if (day < new Date()) {
                        return d; // Blocking out past slots
                      }

                      const isFirstAppointment = patient?.data?.patient?.isFirstAppointment || firstAppointment;
                      const surveyType = patient?.data?.patient?.therapyType;
                      let appointmentDuration = 50; // Default duration

                      if (surveyType === 'couple') {
                        appointmentDuration = 60;
                      } else if (surveyType === 'psychiatry') {
                        appointmentDuration = isFirstAppointment ? 45 : 30;
                      }

                      const slotTime = +getNormalizedDate(day);
                      const startTime = +getNormalizedDate(d.startTime);
                      const endTime = +getNormalizedDate(d.endTime);

                      // Blocking before the start of the appointment
                      const blockBeforeStart =
                        slotTime >= startTime - time.ONE_MINUTE_IN_MILLIS * appointmentDuration &&
                        slotTime < startTime;

                      // Blocking after the end of the appointment
                      const blockAfter =
                        slotTime >= startTime &&
                        slotTime <= endTime

                      return blockBeforeStart || blockAfter;
                    }) ? (
                    <Button key={hour} className={styles.bookedBtn} disabled>
                      <div>{hour}</div>
                      <div>{patientName}</div>
                    </Button>
                  ) : (
                    <Button
                      className={`${styles.freeHourBtn} ${
                        selectedHour === hour && styles.hourSelected
                      }`}
                      key={hour}
                      variant="secondary"
                      onClick={() => {
                        setSelectedHour(hour);
                      }}
                    >
                      {hour}
                    </Button>
                  );
                })}
              </div>
              {showGoDownArrow && (
                <div className={styles.goDown}>
                  <ChevronDownIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {(firstAppointment || patient.data?.patient.isFirstAppointment) && patient?.data?.patient.therapyType !== 'psychiatry' && (
        <div className={styles.firstAppointmentMessage}>
          <InfoCircleOutlined /> Questo primo incontro sarà gratuito
        </div>
      )}
      <div
        className={`${styles.btnWrapper} ${
          !showHourDate && styles.hideContainer
        }`}
      >
        <Button onClick={() => closeModal()} variant="tertiary">
          Annulla
        </Button>
        <Button
          disabled={!selectedHour || openLoadingModal}
          onClick={() =>
            appointmentToModify ? onModifyAppointment() : selectHour()
          }
        >
          Conferma e invia slot
        </Button>
      </div>
    </>
  );
};
