import { useEffect, useMemo, useState } from 'react';
import styles from "./MedicalDocuments.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Document as PdfDocument, Page as PdfPage, pdfjs } from 'react-pdf';
import {
  Document,
  Page,
  PDFViewer,
  PDFDownloadLink,
  BlobProvider,
  StyleSheet as PdfStyleSheet,
  View,
  Text,
  Link,
  Image,
  Font,
  pdf,
} from '@react-pdf/renderer';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Spin } from "antd";
import Input from "../../atoms/Input";
import {
  DocumentArrowDownIcon,
  PencilSquareIcon,
  DocumentArrowUpIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';
import { trpc } from "../../../utils/trpc";
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { useAppDispatch } from '../../../customHooks/reduxHooks';
import Button from '../../atoms/Button';
import Modal from 'components/organisms/Modal';
import FormHeader from '../../atoms/FormHeader';
import { raiseException } from '../../../utils/raiseException';
import { setInfo } from '../../../store/slices/infoSlice';
import { getNormalizedDate } from '../../../utils/dateTime';
import { queryClient } from '../../../index';
import moment from 'moment';
import MedicalNotes from '../MedicalNotes';
import TherapistUploadFirma from 'components/organisms/TherapistUploadFirma';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { envs } from '../../../utils/envs';
import routes from '../../../routes';
import type { CollapseProps } from 'antd';
import { Collapse, Skeleton } from 'antd';

interface Props {
  myTherapist?: any;
  patient?: any;
  chat: any;
  getinformedConsentResponse?: any
}

interface DocumentData {
  id: number | string;
  type: "text" | "widget" | "file" | 'prescription' | 'note';
  fileLocation: string | null;
  when: string;
  from?: string;
  read?: boolean;
  content: string | null;
  appointmentId?: string | null;
  filename: string | null;
  chatId?: string;
  patientId?: string;
  therapistId?: string;
}

interface SpinnerProps {
  size: boolean;
}

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined' && window) {
  // @ts-expect-error This does not exist outside of polyfill which this is doing
  window.Promise.withResolvers = function () {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return {promise, resolve, reject};
  };
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

export const MedicalDocuments = ({myTherapist, patient, chat, getinformedConsentResponse}: Props) => {

  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [activeTab, setActiveTab] = useState<any>(null);
  const [isModalUpload, setIsModalUpload] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState<any>(null);
  const [inputValue, setInputValue] = useState<any>('');
  const [medicines, setMedicines] = useState<any>('');
  const [therapistsToggle, setTherapistToggle] = useState(true);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [signatureName, setSignatureName] = useState<string>('');
  const [patientName, setPatientName] = useState<string>('');
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [isModalSignature, setIsModalSignature] = useState<boolean>(false);
  const [isModalSignatureUpload, setIsModalSignatureUpload] = useState<boolean>(false);
  const [isModalDocument, setIsModalDocument] = useState<boolean>(false);
  const [fileUrlMap, setFileUrlMap] = useState<{ [key: number]: string }>({});
  const [isCollapseOpen, setIsCollapseOpen] = useState<any>(null);
  const [linesPerPage, setLinesPerPage] = useState<string[]>([]);

  Font.register({
      family: 'Poppins',
      fonts: [
        {
          src: '/assets/fonts/Poppins-Regular.ttf',
          fontWeight: 400,
        },
        {
          src: '/assets/fonts/Poppins-SemiBold.ttf',
          fontWeight: 500,
        },
      ],
    },
  );


  const dispatch = useAppDispatch();
  dayjs.locale('it');

  const {data: fetchedData, isLoading} = trpc.mainService.medicalDocuments.getMedicalDocuments.useQuery(
    {therapistId: myTherapist.id, patientId: patient.id, chatId: chat?.id},
  );

  const therapistPreferences =
    trpc.mainService.therapist.getPreferences.useQuery(undefined, {enabled: myTherapist.role === 'therapist'});

  const generateDownloadFileUrl =
    trpc.mainService.chat.generateDownloadFileUrl.useMutation();

  const generateUploadPicUrl =
    trpc.mainService.therapist.generateUploadPicUrl.useMutation();

  const addMedicalDocument = trpc.mainService.medicalDocuments.addMedicalDocument.useMutation();


  const isPatientPsychiatry = patient?.therapyType === 'psychiatry'
  const isNotPsychiatry = (patient?.role === 'patient' && patient?.therapyType !== 'psychiatry') ||
    (myTherapist.role === 'therapist' && !myTherapist.therapies?.includes('psychiatry'))

  useEffect(() => {
    if (therapistsToggle && myTherapist.role === 'therapist') {
      if (therapistPreferences.status === 'loading') {
        setLoadingToggle(true);
        setLoading(true)
      } else if (
        therapistPreferences.status === 'success'
      ) {
        const patientName = getinformedConsentResponse?.informedConsent
          ? `${getinformedConsentResponse.informedConsent.givenName} ${getinformedConsentResponse.informedConsent.familyName}`.trim()
          : '';
        isNotPsychiatry ? setActiveTab(2) : setActiveTab(1)
        setPatientName(patientName);
        setPdfBlob(null);
        setSignatureName(therapistPreferences?.data?.preferences?.['signature']);
        setLoadingToggle(false);
        setTherapistToggle(false);
        setLoading(false);
      }
    }
    if (therapistsToggle && patient?.role === 'patient') {
      setActiveTab(2);
      setLoadingToggle(false);
      setTherapistToggle(false);
      setLoading(false);
    }
  }, [
    therapistsToggle,
    therapistPreferences.status
  ]);

  const data: DocumentData[] = useMemo(() => {
    if (activeTab === 1) {
      return fetchedData?.documents?.prescriptions || [];
    } else if (activeTab === 2 || isNotPsychiatry) {
      return fetchedData?.documents?.attachments || [];
    }
    return [];
  }, [activeTab, fetchedData]);

  const groupedDocuments = useMemo(() => {
    return data.reduce((acc: Record<string, DocumentData[]>, document: DocumentData) => {
      const date = dayjs(document.when);
      const monthYear = date.format('YYYY-MM');
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(document);
      return acc;
    }, {});
  }, [data]);

  const documentsToShow = useMemo(() => {
    return Object.entries(groupedDocuments).reduce((acc, [key, documents]) => {
      const [year, month] = key.split('-');
      if (
        (!selectedYear || selectedYear === year) &&
        (!selectedMonth || selectedMonth === month)
      ) {
        acc[key] = documents;
      }
      return acc;
    }, {} as Record<string, DocumentData[]>);
  }, [groupedDocuments, selectedYear, selectedMonth]);

  const years = useMemo(() => Array.from(new Set(data?.map(doc => dayjs(doc.when).format('YYYY')))), [data]);
  const months = useMemo(() => Array.from(new Set(data?.map(doc => dayjs(doc.when).format('MM')))), [data]);

  const fetchFileUrl = async (id: number | string) => {
    const fileId = typeof id === 'number' ? 'messageId' : 'documentId';

    const {url} = await generateDownloadFileUrl.mutateAsync({
      [fileId]: id
    });
    return url;
  };

  useEffect(() => {
    const preloadFileUrls = async () => {
      const urlPromises = documentsToShow
        ? Object.entries(documentsToShow).flatMap(([_, documents]) =>
          documents.map(async ({id, filename}) => {
            const url = await fetchFileUrl(id);
            const fileExtension = filename?.split('.').pop()?.toLowerCase();

            if (fileExtension === 'pdf') {
              try {
                const response = await fetch(url);
                if (!response.ok) throw new Error('Failed to fetch PDF');
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                return {id, url: blobUrl};
              } catch (error) {
                console.error(`Error fetching PDF with id ${id}:`, error);
                return {id, url: ''};
              }
            } else {
              return {id, url};
            }
          })
        )
        : [];

      const urls = await Promise.all(urlPromises);
      const urlMap = urls.reduce((acc, {id, url}) => {
        acc[id] = url;
        return acc;
      }, {} as { [key: number]: string });

      setFileUrlMap(urlMap);
    };

    preloadFileUrls();
  }, [documentsToShow]);

  const downloadFile = (fileUrl: string, filename: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', filename || 'file');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const Spinner = ({size}: SpinnerProps) => {
    return (
      <Spin
        indicator={<LoadingOutlined style={!size ? {} : {fontSize: 32}}/>}/>
    )
  }

  const handleDocumentUpload = () => {
    setPdfBlob(null);
    setMedicines('');
    setLinesPerPage([]);
    setInputValue('');
    setIsModalUpload(true);
  };

  useEffect(() => {
    if (!loading) {
      if (medicines && signatureName && pdfBlob && linesPerPage.length !== 0) {
        handleUpload()
      }
      if (medicines && !signatureName && linesPerPage.length !== 0) {
        setIsModalUpload(false);
        setSelectedFile(null);
        setIsModalSignature(true);
      }
      if (medicines && !linesPerPage.length) {
        const pages = calculateLinesPerPage(medicines, 271, 30, 509);
        setLinesPerPage(pages);
      }
      if (!medicines && pdfBlob) {
        setPdfBlob(null)
      }
    }
  }, [medicines, pdfBlob, signatureName, linesPerPage]);

  const medicalDocumentUpload = async (file: Blob | File) => {
    const therapistEmail = myTherapist.email;
    const therapistId = myTherapist.id;
    if (therapistEmail && therapistId && patient.id) {
      try {
        const uploadDate = moment(getNormalizedDate()).format('DD_MM_YYYY_HH_MM_SS')
        const documentType = 'prescription';
        const fileName = `${documentType}-${uploadDate}`;
        const {url} = await generateUploadPicUrl.mutateAsync({
          folder: 'docs',
          email: therapistEmail,
          userRegistered: true,
          fileName
        });

        await fetch(url, {
          method: 'PUT',
          body: file,
        });

        await addMedicalDocument.mutateAsync({
          therapistId: therapistId,
          patientId: patient.id,
          type: documentType,
          temporaryFileName: fileName,
          filename: file instanceof File ? file.name : 'document.pdf',
          location: true,
        }).then(
          () => {
            queryClient.removeQueries();
            setIsModalUpload(false);
            setSelectedFile(null);
            setFilePreviewUrl(null);
            setPdfBlob(null);
            setMedicines('');
            setLinesPerPage([]);
            setIsModalDocument(false);
            setLoadingToggle(true);
            dispatch(
              setInfo({
                text: 'Il file è stato caricato con successo',
                variant: 'confirm',
              })
            );
          }
        )
      } catch (error) {
        console.log('Error: ', error)
        raiseException(error);
        dispatch(
          setInfo({
            variant: 'error',
            text: 'Si è verificato un errore durante il caricamento del file',
            title: 'Error',
          })
        );
      }
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFilePreviewUrl(fileUrl);
      setPdfBlob(null);
    }
  };

  const handleUpload = async () => {
    if (pdfBlob) {
      await medicalDocumentUpload(pdfBlob);
    } else if (selectedFile) {
      await medicalDocumentUpload(selectedFile);
    }
  };

  const shouldShowButton = ((myTherapist?.role === 'therapist' && myTherapist?.therapies?.includes('psychiatry') && activeTab === 1)
  );

  const params = new URLSearchParams({
    therapistId: patient?.therapistId,
    therapyType: patient?.therapyType
  }).toString();

  const viewInformedConsent = () => {
    window.open(`${routes.InformedConsentView.path.replace(/\/:[^\/]+$/, '')}/${patient.id}?${params}`, '_blank');
  }

  const InformedConsentButton: any = () => {
    const isDisabled = (
      (patient.therapyType === 'single' && !getinformedConsentResponse?.informedConsent) ||
      (patient.therapyType === 'psychiatry' && !getinformedConsentResponse?.informedConsent) ||
      (patient.therapyType === 'underage' && (!getinformedConsentResponse?.otherInformedConsent && !getinformedConsentResponse?.informedConsent)) ||
      (patient.therapyType === 'couple' && !getinformedConsentResponse?.informedConsent)
    );

    return (
      <Button
        disabled={isDisabled}
        className="small"
        variant="secondary"
        onClick={() => viewInformedConsent()}
      >
        Consenso informato
      </Button>
    );
  };

  const InputWrapper: any = ({className = ''}: any) => {
    return (
      <div className={`${styles.inputsWrapper} ${className}`}>
        <Input
          placeholder="Mese"
          select
          medical
          options={[{
            name: 'Tutti i mesi',
            value: ''
          }, ...months.map(month => ({
            name: dayjs().month(parseInt(month, 10) - 1).format('MMMM').charAt(0).toUpperCase() + dayjs().month(parseInt(month, 10) - 1).format('MMMM').slice(1),
            value: month
          }))]}
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        />
        <Input
          placeholder="Anno"
          select
          medical
          options={[{name: 'Tutti gli anni', value: ''}, ...years.map(year => ({name: year, value: year}))]}
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        />
      </div>
    )
  }

  const Buttons: any = ({collapse = ''}: any) => {
    return (
      <>
        {!isNotPsychiatry &&
          <Button
            className="small"
            onClick={(e) => collapse ? handleClickCollapse(e, 1, '2') : setActiveTab(1)}
            variant={activeTab === 1 ? 'primary' : 'secondary'}
          >
            {isPatientPsychiatry ? 'Prescrizioni' : 'Allegati ricevuti'}
          </Button>
        }
        <Button
          className="small"
          onClick={(e) => collapse ? handleClickCollapse(e, 2, '3') : setActiveTab(2)}
          variant={activeTab === 2 ? 'primary' : 'secondary'}
        >
          {isPatientPsychiatry ? 'Documentazione  sanitaria' : 'Allegati'}
        </Button>
        {myTherapist.role === 'therapist' && (
          <>
            <Button
              className="small"
              onClick={(e) => collapse ? handleClickCollapse(e, 3, '4') : setActiveTab(3)}
              variant={activeTab === 3 ? 'primary' : 'secondary'}
            >
              Note
            </Button>
            <InformedConsentButton
            />
          </>
        )}
      </>
    )
  }

  const removeTrailingSpacesAndEmptyLines = (text: string): string => {
    const sanitizedText = text.replace(/<[^>]*>?/gm, '');

    let lines = sanitizedText.split('\n').map(line => line.trimEnd());

    while (lines.length > 0 && lines[lines.length - 1] === '') {
      lines.pop();
    }

    return lines.join('\n');
  };

  const calculateLinesPerPage = (text, maxHeight, lineHeight, maxWidth) => {
    const sanitizedText = removeTrailingSpacesAndEmptyLines(text);
    const wordsPerLineFactor = lineHeight / 6.2;
    const lines: any = sanitizedText.split(/\n/);

    const currentLines = lines.reduce((acc, line) => {
      let currentLine: any = '';
      const words = line.split(' ');

      words.forEach((word) => {
        const testLine = currentLine + word + ' ';
        const testWidth = testLine.length * wordsPerLineFactor;

        if (testWidth < maxWidth) {
          currentLine = testLine;
        } else {
          acc.push(currentLine);
          currentLine = word + ' ';
        }
      });

      acc.push(currentLine || ' ');
      return acc;
    }, []);

    const maxLines = Math.floor(maxHeight / lineHeight);
    const pages: any = [];

    for (let i = 0; i < currentLines.length; i += maxLines) {
      pages.push(currentLines.slice(i, i + maxLines).join('\n'));
    }

    return pages;
  };

  const pdfStyles = PdfStyleSheet.create({
    page: {
      fontFamily: 'Poppins',
    },
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 842,
      width: 595,
      lineHeight: 2,
      fontSize: 9,
    },
    header: {
      height: '130px',
      backgroundColor: '#a9ceea',
      padding: '16px 50px 20px 50px',
      alignItems: 'center',
      textAlign: 'center',
    },
    headerTextWrapper: {
      alignItems: 'center',
    },
    headerLink: {
      color: '#864507',
    },
    logo: {
      width: 160,
      objectFit: 'contain',
      marginBottom: 10
    },
    footer: {
      backgroundColor: '#a9ceea',
      height: '70px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerContent: {
      lineHeight: 0,
    },
    footerNumberPages: {
      marginTop: 8
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      backgroundColor: '#ffffff',
    },
    data: {
      alignSelf: 'flex-end',
      marginTop: 16,
      marginRight: 50,
    },
    sectionOne: {
      marginLeft: 70,
      marginTop: 30,
      marginRight: 16
    },
    forWrapper: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16
    },
    titleFor: {
      fontWeight: 500,
    },
    contentFor: {
      fontWeight: 400,
    },
    prescribesTitle: {
      marginTop: 16,
      fontWeight: 500,
    },
    patientTaxCodeTitle: {
      fontWeight: 500,
    },
    prescribesTContent: {
      lineHeight: 1.5,
      fontSize: 9,
    },
    sectionTwo: {
      alignSelf: 'flex-end',
      alignItems: 'center',
      marginRight: 50,
      marginTop: 50,
      lineHeight: 1.5
    },
    medicalSurgeon: {
      marginTop: 16
    },
    psychiatry: {},
    registrationNumber: {
      marginTop: 16
    },
    signatureContainer: {
      justifyContent: 'center',
      marginTop: 16,
      marginBottom: 16,
      width: 180,
      height: 180,
    },
    signature: {
      objectFit: 'contain',
    }
  });

  const Doc: any = () => {
    return (
    <Document>
      {linesPerPage.map((pageText, index) => (
        <Page wrap={false} key={index} size="A4" style={pdfStyles.page}>
          <View fixed style={pdfStyles.pageContainer}>
            <View style={pdfStyles.header}>
              <Image style={pdfStyles.logo} src="/assets/images/landingPageImgs/logo-new.png"></Image>
              <View style={pdfStyles.headerTextWrapper}>
                <Text>MYMENTIS S.R.L. | Via Montegrappa 43, Varazze (SV), 17019 | PEC <Link
                  style={pdfStyles.headerLink} href="mailto: mymentis@pec.it">mymentis@pec.it</Link> |
                  Numero
                  REA SV – 236781 | </Text>
                <Text>Codice fiscale e n.iscr. al Registro Imprese 01886110095</Text>
              </View>
            </View>
            <View style={pdfStyles.main}>
              <Text style={pdfStyles.data}>{moment(getNormalizedDate()).format('DD/MM/YYYY')}</Text>
              <View style={pdfStyles.sectionOne}>
                <View style={pdfStyles.forWrapper}>
                  <Text style={pdfStyles.titleFor}>Per il </Text>
                  <Text
                    style={pdfStyles.contentFor}>Sign / Sign.ra: {patientName}</Text>
                </View>
                <Text style={pdfStyles.patientTaxCodeTitle}>Codice fiscale del paziente:</Text>
                <Text>{getinformedConsentResponse?.informedConsent?.taxId ? getinformedConsentResponse?.informedConsent?.taxId : ' '}</Text>
                <Text style={pdfStyles.prescribesTitle}>
                  Si prescrive:
                </Text>
                <Text style={pdfStyles.prescribesTContent}>
                  {pageText}
                </Text>
              </View>
              <View style={pdfStyles.sectionTwo}>
                <Text>
                  <Text>DOTTOR / DOTT.SSA: </Text>
                  <Text>{myTherapist?.given_name} {myTherapist?.family_name}</Text>
                </Text>
                <Text style={pdfStyles.medicalSurgeon}>
                  Medico Chirurgo
                </Text>
                <Text style={pdfStyles.psychiatry}>
                  Specialista in psichiatria
                </Text>
                <Text style={pdfStyles.registrationNumber}>
                  {myTherapist?.licenceRegisterPlace}
                </Text>
                <View style={pdfStyles.signatureContainer}>
                  {signatureName &&
                    <Image style={pdfStyles.signature} src={`${envs.REACT_APP_S3_ENDPOINT}/${signatureName}`}></Image>
                  }
                </View>
              </View>
            </View>
            <View style={pdfStyles.footer}>
              <Text style={pdfStyles.footerContent}>
                Direttore sanitario: Davide Bianchi | Iscritto all’albo della provincia di Imperia, N° 2078
              </Text>
              {linesPerPage.length > 1 &&
                <Text style={pdfStyles.footerNumberPages}>
                  Page: {index + 1} / {linesPerPage.length}
                </Text>
              }
            </View>
          </View>
        </Page>
      ))}
    </Document>
    )
  };

  const content = useMemo(() => {
    return (
      <div className={styles.bottomPart}>
        <div className={styles.filters}>
          {!(activeTab && data.length === 0) &&
            <InputWrapper className={isNotPsychiatry && patient?.role === 'patient' ? '' : 'display-none'}/>
          }
          {shouldShowButton && (
            <Button className="smallFilter" variant="secondary" leftIcon={<PencilSquareIcon/>}
                    onClick={handleDocumentUpload}>
              Nuova Prescrizione
            </Button>
          )}
        </div>
        {activeTab && data.length === 0 ?
          <div
            className={styles.noDocuments}>Non sono presenti documenti
          </div> :
          isLoading || loading ?
            <Spinner size={true}/> :
            <div className={styles.documents}>
              {documentsToShow && Object.entries(documentsToShow).map(([monthYear, documents]) => (
                <div key={monthYear} className={styles.month}>
                  <h5>{dayjs(monthYear, 'YYYY-MM').format('MMMM')}</h5>
                  <div className={styles.previews}>
                    {documents.map(({when, filename, id}, index) => (
                      <div key={index} className={styles.previewContainer}>
                        <div className={styles.preview}
                        >
                          {!fileUrlMap[id] ?
                            <Skeleton.Image active
                                            style={{width: 85}}/> : filename?.split('.').pop()?.toLowerCase() === 'pdf' ?
                              <PdfDocument onClick={() => downloadFile(fileUrlMap[id], filename)}
                                           loading={<Skeleton.Image active style={{width: 85}}/>}
                                           file={fileUrlMap[id]}
                              >
                                <PdfPage
                                  className={styles.page}
                                  pageNumber={1}
                                  width={75}
                                  loading=""
                                />
                              </PdfDocument> :
                              filename?.split('.').pop()?.toLowerCase() === 'png' ||
                              filename?.split('.').pop()?.toLowerCase() === 'jpeg' ||
                              filename?.split('.').pop()?.toLowerCase() === 'jpg' ? (
                                <img onClick={() => downloadFile(fileUrlMap[id], filename)} className={styles.image}
                                     src={fileUrlMap[id]} alt={filename || 'img'}/>
                              ) : <div className={styles.iconWrapper}><DocumentArrowDownIcon/></div>
                          }
                        </div>
                        <div className={styles.description}>
                          <p>{filename}</p>
                          <p>{dayjs(when).format('DD/MM/YYYY')}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
        }
      </div>
    );
  }, [loading, isLoading, fileUrlMap])

  const handleClickCollapse = (e: any, page: any, collapse: any) => {
    e.preventDefault();
    setActiveTab(page)
    setIsCollapseOpen((prevKey: any) => (prevKey === collapse ? null : collapse));

  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (() => {
        if (activeTab === 1) {
          return isPatientPsychiatry ? 'Prescrizioni' : 'Allegati ricevuti';
        } else if (activeTab === 2) {
          return isPatientPsychiatry ? 'Documentazione sanitaria' : 'Allegati inviati';
        } else if (activeTab === 3) {
          return 'Note';
        }
        return '';
      })(),
      styles: {
        header: {
          fontSize: '1rem',
          textAlign: 'start',
          color: '#2C2C4B',
          fontFamily: 'Poppins',
          fontWeight: '500'
        }
      },
      children: <div className={styles.collapseList}>
        <Buttons collapse/>
      </div>
    },
  ];

  const openDocumentInNewTab = async () => {
    const blob = await pdf(<Doc/>).toBlob();
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, '_blank');
  };

  const modalDocument = useMemo(() => {
    return (
      <Modal modal subClassName="modalWithSmallHeaderMobile" close={() => {
        setIsModalDocument(false);
      }}>
        <FormHeader
          title="Completa la firma: scarica e carica il documento"
          closeModal={() => {
            setIsModalDocument(false);
          }}
          closeButton
        />
        <div className={styles.uploadContainer}>
          {!selectedFile ?
            <div className={styles.pdfViewerWrapper}>
              <PDFViewer showToolbar={false} width="250"
                         height="353">
                <Doc/>
              </PDFViewer>
              <div className={styles.pdfViewerBackdrop} onClick={openDocumentInNewTab}></div>
            </div>
            :
            <div className={styles.fileWrapper}>
              {selectedFile.type === 'application/pdf' ? (
                <PdfDocument file={filePreviewUrl} loading={
                  <Spin
                    className={styles.spinner}
                    indicator={<LoadingOutlined style={{fontSize: 32, color: '#3576bb'}} spin/>}
                  />
                }>
                  <PdfPage pageNumber={1} width={250} loading=""/>
                </PdfDocument>
              ) : (
                <img src={filePreviewUrl} alt={selectedFile.name} width={250}/>
              )}
              <p>Nome file: {selectedFile.name}</p>
              <p>Dimensione: {(selectedFile.size / 1024).toFixed(2)} KB</p>
              <div className={styles.buttonsContainer}>
                <Button leftIcon={<ArrowDownTrayIcon/>} className={styles.medical} variant="secondary"
                        onClick={handleUpload}>Salva file</Button>
                {/*<Button leftIcon={<TrashIcon/>} className={styles.medical} variant="secondary"*/}
                {/*        onClick={() => {*/}
                {/*          setSelectedFile(null);*/}
                {/*          setFilePreviewUrl(null);*/}
                {/*          setIsModalUpload(false);*/}
                {/*        }}>Cancellare il file</Button>*/}
              </div>
            </div>
          }
          <Input
            className={styles.inputWrapper}
            label="Aggiungi file"
            sublabel="La foto deve essere in formato png oppure jpeg, pdf"
            type="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={handleFileChange}
            checkboxId="hiddenFileInput"
          />
          {!selectedFile &&
            <div className={styles.buttonsContainer}>
                    <span className={styles.wrapper}>
                      Scarica il documento, firmalo (a mano o tramite strumenti specializzati) e caricalo nuovamente su Mymentis.<br/>
                      Assicurati che la firma sia leggibile e conforme.
                      Una volta firmato, segui le istruzioni per il caricamento.
                    </span>
              <div className={styles.wrapper}>
                <span>1. Scarica il documento: </span>
                <Button className={styles.medicalDownload} leftIcon={<DocumentArrowDownIcon/>}>
                  <PDFDownloadLink document={<Doc/>} fileName="document.pdf">
                    Scarica PDF
                  </PDFDownloadLink>
                </Button>
              </div>
              <div className={styles.wrapper}>
                <span>2. Carica il documento firmato: </span>
                <Button leftIcon={<DocumentArrowUpIcon/>}
                        className={styles.medicalButton}
                        onClick={() => document?.getElementById('hiddenFileInput')?.click()}
                >Carica PDF
                </Button>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  }, [isModalDocument, selectedFile])

  return (
    <div className={styles.medicalWrapper}>
      {loading || isLoading ? <Modal>
          <Spin indicator={<LoadingOutlined style={{fontSize: 32}} spin/>}/>
        </Modal> :
        <>
          {isModalUpload &&
            <Modal modal subClassName="modalWithSmallHeaderMobile" close={() => {
              setIsModalUpload(false);
            }}>
              <FormHeader
                title={activeTab === 1 ? 'Nuova Prescrizione' : 'Nuova documentazione'}
                closeModal={() => {
                  setIsModalUpload(false);
                }}
                closeButton
              />
              <div className={styles.textAreaWrapper}>
                <Input
                  placeholder="Medicine e dosaggi"
                  className={styles.input}
                  textarea
                  label="Compilare i dati relativi ai farmaci e ai dosaggi prescritti dal medico."
                  minLength={5}
                  value={inputValue}
                  name="text"
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <Button disabled={inputValue.length < 5} className={styles.button} variant="secondary"
                        leftIcon={signatureName ? <ArrowDownTrayIcon/> : <PencilSquareIcon/>}
                        onClick={() => setMedicines(inputValue)}>
                  {signatureName ? 'Salva' : 'Proseguire'}
                </Button>
              </div>
            </Modal>
          }
          {(signatureName && medicines && !selectedFile && linesPerPage.length > 0) &&
            <BlobProvider document={<Doc/>}>
              {({blob, loading, error}) => {
                if (!loading && !error && blob && !pdfBlob) {
                  setPdfBlob(blob);
                }
                return null;
              }}
            </BlobProvider>
          }
          {medicines && !signatureName && isModalSignature &&
            <Modal modal subClassName="max-width-40" close={() => {
              setIsModalSignature(false);
            }}>
              <FormHeader
                title="Vuoi caricare la tua firma su Mymentis?"
                closeModal={() => {
                  setIsModalSignature(false);
                }}
                closeButton
              />
              <span>
                Per garantire la massima sicurezza, ti consigliamo di utilizzare una firma digitale tramite strumenti specializzati.
                Se preferisci, puoi anche scaricare il documento e firmarlo manualmente o caricare un'immagine con la tua firma.
              </span>
              <div className={styles.buttonsContainer}>
                <Button className={styles.medicalConfirmation} onClick={() => {
                  setIsModalSignatureUpload(true)
                  setIsModalSignature(false)
                }}
                >Sì, carica la mia firma</Button>
                <Button className={styles.medical} onClick={() => {
                  setIsModalSignature(false)
                  setIsModalDocument(true)
                }}
                >No, prosegui</Button>
              </div>
            </Modal>
          }
          {medicines && !signatureName && isModalSignatureUpload &&
            <Modal modal close={() => {
              setIsModalSignatureUpload(false);
            }}>
              <FormHeader
                title="Aggiungere la Firma"
                closeModal={() => {
                  setIsModalSignatureUpload(false);
                }}
                closeButton
              />
              <TherapistUploadFirma userInfo={myTherapist}
                                    setIsModalOpen={setIsModalSignatureUpload}
                                    signatureName={signatureName}
                                    isModal
                                    setTherapistToggle={setTherapistToggle}
              />
            </Modal>
          }
          {isModalDocument && modalDocument}
          {(isNotPsychiatry && patient?.role === 'patient') ?
            <div className={styles.container}>{content}</div> :
            <>
              <div className={styles.bottomWrapper}>
                <Buttons collapse={false}/>
              </div>
              <div className={styles.collapseWrapper}>
                <Collapse className={styles.collapse} activeKey={isCollapseOpen}
                          onChange={(key) => setIsCollapseOpen(key)} accordion
                          expandIconPosition="end"
                          ghost
                          items={items}/>
                <InputWrapper className="mobile"/>
              </div>
              {(activeTab === 1 || activeTab === 2) && content}
              {activeTab === 3 &&
                <MedicalNotes
                  patient={patient}
                  therapist={myTherapist}
                />
              }
            </>
          }
        </>
      }
    </div>
  );
};
