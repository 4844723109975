import { envs } from './envs';

const iubendaApiUrl = envs.REACT_APP_IUBENDA_API_URL;
const iubendaApiKey = envs.REACT_APP_IUBENDA_PRIVATE_API_KEY;

export const createIubendaConsent = async (body: any) => {
  const response: any = await fetch(`${iubendaApiUrl}/consent`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      'ApiKey': `${iubendaApiKey}`,
    },
  });

  const result = await response.json();

  return result?.subject_id;
}

export const updateIubnebdaSubject = async (subjectId: string, body: any) => {
  await fetch(`${iubendaApiUrl}/subjects/${subjectId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      'ApiKey': `${iubendaApiKey}`,
    },
  });
}
