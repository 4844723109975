import styles from './PatientBookingFirstAppointment.module.scss';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { trpc } from '../../utils/trpc';
import { envs } from '../../utils/envs';
import Avatar from '../../components/atoms/Avatar';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import Modal from '../../components/organisms/Modal';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import card from '../../theme/images/card.svg'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowUturnLeftIcon
} from '@heroicons/react/20/solid';
import Button from '../../components/atoms/Button';
import routes from '../../routes';
import Emitter from '../../utils/eventemitter';
import { getAvailableDates } from '../../utils/avaliableDate';
import TherapistFeedbackModal from '../../components/organisms/TherapistFeedbackModal';
import ModalCurrentTherapist from '../../components/organisms/ModalCurrentTherapist';
import { checkIf48HoursPassed } from '../../utils/checkIf48HoursPassed';
import { setInfo } from '../../store/slices/infoSlice';
import { useAppDispatch } from '../../customHooks/reduxHooks';
import { raiseException } from '../../utils/raiseException';
import { checkCompletedAppointmentExist, firstAppointmentExist } from '../../utils/checkAppointmentExist';
import { getNormalizedDate, getNormalizedTime, currentUserLocationIsItaly } from '../../utils/dateTime';
import PatientPaymentForm from '../../components/PatientPaymentForm';
import FormHeader from '../../components/atoms/FormHeader';
import PatientPayment from '../../components/organisms/PatientPayment';
import { getParsedCPS } from '../../utils/getParsedCPS';
import { useTherapistData } from '../../customHooks/therapistDataContext';
import { sendWhatsappMessages } from '../../utils/whatsapp';
import { queryClient } from 'index';

interface TherapistCardProps {
  isMultiCards?: boolean;
  therapist: any;
  index?: number;
}

export const PatientBookingFirstAppointment = ({className}) => {
  const socket = useRouteLoaderData("firstAppointmentRoot") as Socket;
  const temporaryTherapistId: any = localStorage.getItem('temporaryTherapistId');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedChat, setSelectedChat] = useState<string | undefined>('');
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const [therapistsToggle, setTherapistToggle] = useState(true);
  const [therapistId, setTherapistId] = useState<any>('');
  const [loading, setLoading] = useState(true);
  const [completedAppointment, setIsCompletedAppointment] = useState<boolean>(false);
  const [specToShow, setSpecToShow] = useState(3);
  const [expandedCards, setExpandedCards] = useState<any>({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBillingModal, setIsBillingModal] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [suggestedTherapists, setSuggestedTherapists] = useState<any>([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isFirstRegister, setIsFirstRegister] = useState<boolean | undefined>(undefined);
  const [showCurrentTherapistModal, setShowCurrentTherapistModal] =
    useState(false);
  const [showChooseTherapistModal, setShowChooseTherapistsModal] =
    useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalChildren, setModalChildren] = useState<JSX.Element | undefined>();
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const {therapistData, removeTherapistData} = useTherapistData();

  const createAppointment =
    trpc.mainService.appointment.createAppointment.useMutation();

  const createWidgetMessage =
    trpc.mainService.appointment.createWidgetMessage.useMutation();

  const deleteAppointment = trpc.mainService.appointment.deleteAppointment.useMutation();

  const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined});

  const getSuggestedTherapist: any =
    trpc.mainService.patient.getSuggestedTherapists.useQuery({firstRegistration: !!isFirstRegister}, {
      enabled: false,
    });

  const getMyTherapist: any = trpc.mainService.patient.getMyTherapist.useQuery(
    {therapistId: therapistData?.therapistId || (isFirstRegister && temporaryTherapistId) || undefined},
    {enabled: userInfo?.data?.user.role === 'patient' && isFirstRegister !== undefined && (isFirstRegister ? !!temporaryTherapistId : true)}
  );

  const myTherapist: any = getMyTherapist?.data?.therapist

  const getMyTherapistFreeDates = trpc.mainService.patient.getTherapistAvailability.useQuery(
    {
      therapistId: therapistData?.therapistId || myTherapist?.id || temporaryTherapistId,
      patientId: userInfo?.data?.user?.id
    },
    {enabled: userInfo?.data?.user.role === 'patient' && !!myTherapist?.id}
  );

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {patientId: userInfo?.data?.user.id},
    {enabled: userInfo?.data?.user.role === 'patient'}
  )

  const getBillingDetailsResponse =
    trpc.mainService.patient.getBillingDetails.useQuery(undefined, {
      enabled: loading && userInfo?.data?.user.role === 'patient'
    })

  const getPaymentIntent =
    trpc.mainService.payments.stripe.getPaymentIntent.useMutation();

  const chooseTherapists =
    trpc.mainService.patient.chooseTherapist.useMutation();

  const isPsychiatry = (therapistData?.therapyType ?? userInfo?.data?.user?.therapyType) === 'psychiatry';
  const isTherapistPsychiatry = therapistData?.therapyType === 'psychiatry' || myTherapist?.therapyType?.includes('psychiatry');

  useEffect(() => {
    if (!isAllFieldsFilled && !isBillingModal && isPsychiatry && !therapistsToggle) {
      setIsAllFieldsFilled(userInfo?.data?.user.role === 'patient' && areAllFieldsFilled(getBillingDetailsResponse?.data?.billingDetails))
    }
  }, [isAllFieldsFilled, therapistsToggle]);


  function areAllFieldsFilled(obj: any) {
    if (!isPsychiatry) {
      return true;
    }
    if (obj && Object.keys(obj).length > 0) {
      return Object.values(obj).every(value => Boolean(value));
    }
    return false;
  }

  const interventionAreas = myTherapist?.specializations || [];

  const handleBillingSubmit = async () => {
    const result = await getBillingDetailsResponse.refetch()
    if (result.status === "success") {
      const allFieldsFilled = areAllFieldsFilled(result.data.billingDetails)
      setIsAllFieldsFilled(allFieldsFilled)
      if (allFieldsFilled) {
        await createFirstAppointment(allFieldsFilled).then(() => setIsBillingModal(false))
      }
    }
  }

  const handleChooseTemporaryTherapist = async (id: string) => {
    try {
      setLoading(true)
      localStorage.setItem('temporaryTherapistId', id);
      setShowChooseTherapistsModal(false)
      setSelectedDateTime(null)
      setSuggestedTherapists([])
      await userInfo.refetch()
      await getMyTherapist.refetch()
      await getMyTherapistFreeDates.refetch()
      setTherapistToggle(true)

    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  const handleChooseTherapist = async ({data = false, appointment = false, toChat = false}: {
    data?: boolean,
    appointment?: boolean,
    toChat?: boolean
  }) => {
    try {
      const {assignedTherapist} = await chooseTherapists.mutateAsync({
        therapistId: temporaryTherapistId,
        therapistChange: false
      });

      if (assignedTherapist.id) {
        queryClient.removeQueries();
        await getMyTherapist.refetch();
        await userInfo.refetch().then(result => {
          if (result.status === 'success') {
            const chatObject: any = result?.data?.user?.chats?.find((item) => item.therapistId === assignedTherapist.id);
            setSelectedChat(chatObject?.id)
            setTherapistId(assignedTherapist.id)
            localStorage.removeItem('temporaryTherapistId');
            if (chatObject?.id && appointment) {
              executeMainLogic(chatObject?.id, data);
            }
            if (chatObject?.id && toChat) {
              goToChat(chatObject?.id)
            }
          }
        });
      }
    } catch (error: any) {
      dispatch(
        setInfo({
          variant: 'error',
          text: error.message,
          title: 'Errore scelta terapeuta',
        })
      );
    }
  }

  const executeMainLogic = async (chatId: any, data: boolean) => {
    if (therapistId && selectedDateTime && (selectedChat || chatId) && (isPsychiatry ? data : true)) {
      setIsBillingModal(false);
      const date = getNormalizedTime(new Date(selectedDateTime));
      try {
        setLoading(true)
        const chatObject: any = userInfo?.data?.user?.chats?.find((item) => item.therapistId === therapistId);
        const type = therapistData?.therapyType || chatObject?.therapyType;
        const {appointment} = await createAppointment.mutateAsync({
          startTime: date,
          patientId: userInfo?.data?.user?.id!,
          therapistId: therapistId,
          accountTherapyType: type,
          createdByPatient: true
        });
        if ((selectedChat || chatId) && appointment?.id) {
          try {
            const widget: any = await createWidgetMessage.mutateAsync({
              chatId: selectedChat || chatId,
              type: 'widget',
              appointmentId: appointment?.id
            });

            if (widget?.appointmentData?.id && isPsychiatry) {
              getAppointments.refetch();
              getMyTherapistFreeDates.refetch().then(() => {
                setSelectedDateTime(null);
                setTherapistToggle(true);
              });
              await onPatientPayment(widget?.appointmentData, widget?.newMessage.id);
            }

            if (widget?.appointmentData?.id) {
              socket.emit(
                'sendWidgetMessage',
                widget?.newMessage,
                widget?.appointmentData,
                widget?.chat
              );
              if (!isPsychiatry) {
                navigate(routes.Chat.path);
              }
            }
          } catch (error) {
            try {
              await deleteAppointment.mutateAsync({appointmentId: appointment?.id});
            } catch {
            }
            dispatch(
              setInfo({
                text: 'Si è verificato un errore, prova a creare nuovamente l\'appuntamento.',
                variant: 'error',
              })
            );
          }
        }
      } catch (error: any) {
        raiseException(error);
        if (error?.shape?.errorCode === 'AppointmentInvalidStartTime') {
          dispatch(
            setInfo({
              text: 'L\'ora di inizio selezionata è troppo vicina',
              variant: 'error',
            })
          );
        } else {
          dispatch(setInfo({text: 'Errore inaspettato', variant: 'error'}));
        }
      } finally {
        setLoading(false);
      }
    } else {
      setIsBillingModal(true);
    }
  };

  const createFirstAppointment = async (data: boolean) => {
    if (isFirstRegister) {
      await handleChooseTherapist({data, appointment: true});
    } else {
      await executeMainLogic(undefined, data);
    }
  };

  useEffect(() => {
    Emitter.on("updateTherapistChange", () => setTherapistToggle(true));
  }, []);

  useEffect(() => {
    if (userInfo.status === 'success') {
      if (isFirstRegister !== !userInfo?.data?.user?.therapistId) {
        setIsFirstRegister(!userInfo?.data?.user?.therapistId)
      }

      if (!userInfo?.data?.user?.therapistId && !temporaryTherapistId) {
        navigate(routes.ChooseTherapist.path);
      }

      if (isFirstRegister && !suggestedTherapists?.length && temporaryTherapistId) {
        getSuggestedTherapists()
      }
    }
  }, [userInfo.isFetching, isFirstRegister, temporaryTherapistId]);

  const getSuggestedTherapists = async () => {
    const {data} = await getSuggestedTherapist.refetch();
    const suggestedTherapists = data?.suggestedTherapists || [];
    const matchingTherapist = suggestedTherapists.find(item => item.id === temporaryTherapistId);
    if (suggestedTherapists.length > 0) {
      if (matchingTherapist) {
        setSuggestedTherapists(suggestedTherapists.filter(item => item.id !== temporaryTherapistId))
      } else {
        localStorage.removeItem('temporaryTherapistId');
        navigate(routes.ChooseTherapist.path);
      }

    }
  };

  useEffect(() => {
    if (therapistsToggle && (therapistData || myTherapist?.id)) {
      if (userInfo.status === "loading" || getAppointments.status === 'loading') {
        setLoading(true)
        setShowFeedbackModal(false)
        if (userInfo?.data?.user?.role && userInfo?.data?.user?.role !== 'patient') {
          navigate(routes.Chat.path);
          return;
        }
      } else if (
        !userInfo.isFetching &&
        userInfo.status === "success" &&
        getMyTherapistFreeDates.status === "success" &&
        getBillingDetailsResponse.status === "success" &&
        myTherapist?.id && getAppointments.status === "success"
      ) {
        if (userInfo?.data?.user.therapistId === null && therapistData) {
          removeTherapistData()
          userInfo.refetch()
          setTherapistToggle(true)
          return;
        }
        const therapistId = therapistData?.therapistId || myTherapist?.id;
        const chatObject: any = userInfo?.data?.user?.chats?.find((item) => item.therapistId === therapistId);
        const chat = therapistData?.chat?.id || chatObject?.id;
        const result = checkCompletedAppointmentExist(getAppointments?.data?.appointments, therapistId!);
        setIsCompletedAppointment(result);
        setTherapistToggle(false);
        const therapistFreeDates: any = getMyTherapistFreeDates.data;
        setAvailableDates(
          getAvailableDates(
            therapistFreeDates?.daySlots,
            therapistFreeDates?.timeSlots,
            therapistFreeDates?.appointmentsBusyTime,
            therapistFreeDates?.offlineAppointments
          ))
        setTherapistId(therapistId)
        setSelectedChat(chat)
        setLoading(false)
      }
    }
  }, [
    therapistsToggle,
    userInfo.isFetching,
    getMyTherapistFreeDates.status,
    getBillingDetailsResponse.status,
    myTherapist,
    getAppointments.status
  ]);

  const handleDateClick = (day: string, time: string, available: any) => {
    if (!available) return;
    setSelectedDateTime(`${day} ${time}:00`);
  };

  const closeModal = () => {
    setModalIsVisible(false);
  };

  const onPatientPayment = async (appointment: any, messageId: number) => {
    const onPayAppointment = async () => {
      socket.emit("updateWidgetMessage", messageId, selectedChat);
    };
    const response = await getPaymentIntent.mutateAsync({
      appointmentId: appointment.id,
    });

    setModalChildren(
      <PatientPayment
        isModal
        className="modalWithSmallHeader"
        closeModal={closeModal}
        userInfo={userInfo?.data?.user}
        myTherapist={myTherapist}
        savedPayment={
          userInfo?.data?.user?.stripeDefaultPaymentMethodId ??
          undefined
        }
        setupFutureUsage={Boolean(response.setupFutureUsage)}
        onPayAppointment={onPayAppointment}
        paymentIntentId={response.paymentIntentId}
        clientSecret={response.clientSecret!}
        amount={response.amount / 100}
        appointment={appointment}
      />
    )
    setModalIsVisible(true);
  }

  const changeTherapistButton = () => {
    if (!therapistData || therapistData?.main) {
      return (!userInfo?.data?.user?.changeTherapist && completedAppointment) || isFirstRegister;
    }

    return !therapistData?.changeTherapist && completedAppointment;
  };

  const handleGoToChat = async () => {
    if (isFirstRegister) {
      await handleChooseTherapist({toChat: true});
    } else {
      await goToChat();
    }
  }

  const goToChat = async (id?: string) => {
    const _selectedChat = selectedChat || id
    const appointmentsWithTherapist = getAppointments?.data?.appointments
      .filter((el) => el?.therapist?.id === (therapistData?.therapistId || myTherapist?.id));
    if (!appointmentsWithTherapist?.length && !myTherapist?.therapistSendMessage && envs.REACT_APP_WHATSAPP_ACCESS_TOKEN !== 'x') {
      setLoading(true);
      const phoneNumber = myTherapist?.phone_number;
      const therapistName = `${myTherapist?.given_name} ${myTherapist?.family_name}`.trim();
      const patientName = `${userInfo?.data?.user?.given_name} ${userInfo?.data?.user?.family_name}`.trim();

      const body = {
        messaging_product: 'whatsapp',
        to: phoneNumber,
        type: 'template',
        template: {
          name: `patient_not_booked_any_first_appointment_${envs.REACT_APP_WHATSAPP_TEMPLATE_TYPE}`,
          language: { code: 'it' },
          components: [
            {
              type: 'header',
              parameters:[
                {
                  type: 'text',
                  parameter_name: 'text',
                  text: therapistName
                }
              ]
            },
            {
              type: 'body',
              parameters: [
                {
                  type: 'text',
                  parameter_name: 'text',
                  text: patientName
                }
              ]
            },
            {
              type: 'button',
              sub_type: 'url',
              index: 0,
              parameters: [
                {
                  type: 'text',
                  text: _selectedChat
                }
              ]
            }
          ]
        },
      };

      try {
        await sendWhatsappMessages(body)
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        navigate(routes.Chat.path);
      }
    } else {
      navigate(routes.Chat.path);
    }
  }

  const avatarVersion = useMemo(() => Date.now(), []);

  const TherapistCard: React.FC<TherapistCardProps> = memo(({isMultiCards = false, therapist, index = 0}) => {
    const _isTherapistPsychiatry = useMemo(() => isMultiCards ? therapist?.therapyType?.includes('psychiatry') : isTherapistPsychiatry, [therapist?.id]);

    const handleExpand = useCallback(() => {
      if (isMultiCards) {
        setExpandedCards((prev) => ({
          ...prev,
          [index]: {
            ...(prev[index]),
            isMenuOpen: !prev[index]?.isMenuOpen,
          },
        }));
      } else {
        setIsMenuOpen((prev) => !prev);
      }
    }, [therapist?.id]);

    const interventionList = useMemo(() => {
      const areas = getParsedCPS(
        _isTherapistPsychiatry,
        isMultiCards ? therapist?.specializations : interventionAreas
      );
      return areas ?? [];
    }, [therapist?.id]);

    return (
      <div className={`${styles.therapistInfoContainer} ${isMultiCards ? styles.suggestedTherapists : ''}`}>
        <div className={styles.therapistWrapper}>
          <div className={styles.therapistInfo}>
            <div className={styles.therapistName}>{therapist?.given_name} {therapist?.family_name}
            </div>
            <span className={styles.therapistTitle}>{therapist?.title}</span>
            <div className={`${styles.therapistInfoCard} ${isPsychiatry && styles.isPsychiatry}`}>
              <img src={card} alt="card"/>
              <div className={styles.aboutDoc}>
                <div className={styles.aboutTitle}>
                  Iscritt{therapist?.gender === 'male' ? 'o' : 'a'} {_isTherapistPsychiatry ? 'all’Ordine dei Medici' : `all'albo degli
                        psicologi`}
                </div>
                <div className={styles.aboutText}>
                  Regione {therapist?.licenceRegisterPlace}
                </div>
              </div>
            </div>
          </div>
          <Avatar className={styles.profilePicture}
                  avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${therapist?.id}?t=${avatarVersion}`}/>
        </div>
        <div
          className={`${styles.infoBlock} ${(isMultiCards ? expandedCards[index]?.isMenuOpen : isMenuOpen) ? styles.visible : ''}`}>
          <div className={styles.graduate}>
            <div className={styles.graduateTitle}>
              Laurea conseguita presso
            </div>
            <div className={styles.aboutText}>
              {therapist?.graduationCourse} presso{' '}
              {therapist?.graduationPlace}
            </div>
          </div>
          <div className={styles.interventionArea}>
            <div className={styles.title}>Aree d'intervento</div>
            <div className={styles.areas}>
              {interventionAreas?.length &&
                interventionList.map(
                  (interventionArea, specIndex) => {
                    if (isMultiCards ? (expandedCards[index]?.isExpanded || specIndex < 3) : specIndex < specToShow)
                      return (
                        <Button
                          key={specIndex}
                          variant="secondary"
                          className={styles.areasBtn}
                        >
                          {interventionArea}
                        </Button>
                      );
                  }
                )}
              {interventionList.length > 3 && specToShow === 3 && (
                <Button
                  onClick={() => isMultiCards ? setExpandedCards((prev) => ({
                    ...prev,
                    [index]: {
                      ...(prev[index]),
                      isExpanded: !expandedCards[index]?.isExpanded,
                    },
                  })) : setSpecToShow(interventionList.length)}
                  variant="secondary"
                  className={styles.areasBtn}
                >
                  {isMultiCards ? expandedCards[index]?.isExpanded ? '-' : `+ ${interventionList.length - 3}` :
                    `+ ${interventionAreas && interventionList.length - 3}`}
                </Button>
              )}
              {!isMultiCards && getParsedCPS(_isTherapistPsychiatry, interventionAreas).length > 3 && specToShow > 3 && (
                <Button
                  variant="secondary"
                  className={styles.areasBtn}
                  onClick={() => setSpecToShow(3)}
                >
                  -
                </Button>
              )}
            </div>
          </div>
        </div>
        {(changeTherapistButton()) && !isMultiCards && (isFirstRegister ? !!suggestedTherapists?.length : true) && (
          <Button
            className={styles.changeTherapistButton}
            variant="secondary"
            onClick={() => isFirstRegister ? setShowChooseTherapistsModal(true) : setShowCurrentTherapistModal(true)}
          >
            {isPsychiatry ? 'Cambia psichiatra' : 'Cambia terapeuta'}
          </Button>
        )}
        {isMultiCards &&
          <Button
            className={`${styles.changeTherapistButton} ${styles.temporary}`}
            variant="secondary"
            onClick={() => handleChooseTemporaryTherapist(therapist?.id)}
          >
            Scegli {therapist?.given_name} {therapist?.family_name?.[0]?.toUpperCase()}.
          </Button>
        }
        <div className={styles.infoBlockButton} onClick={handleExpand}>
          <span>Mostra la scheda</span>
          {(isMultiCards ? !expandedCards[index]?.isMenuOpen : !isMenuOpen) ? <ChevronDownIcon height={25}/> :
            <ChevronUpIcon height={25}/>}
        </div>
      </div>
    )
  })

  return (
    <div className={`${styles.firstAppointmentBookingContainer} ${className}`}>
      {isBillingModal && isPsychiatry && !modalIsVisible && (
        <Modal modal className="modalWithSmallHeader" subClassName="modalWithSmallHeader" close={() => {
          setIsBillingModal(false);
        }}>
          <FormHeader
            title="Dati di fatturazione"
            closeModal={() => {
              setIsBillingModal(false);
            }}
            closeButton
          />
          <PatientPaymentForm
            getBillingDetailsResponse={getBillingDetailsResponse}
            isModal={false}
            userInfo={userInfo?.data?.user}
            setLoadingModalIsOpen={handleBillingSubmit}
            closeModal={setIsBillingModal}
          />
        </Modal>
      )}
      {modalIsVisible && !isBillingModal && isPsychiatry &&
        <Modal className="modalWithSmallHeader">{modalChildren}</Modal>}
      {(loading || isFirstRegister === undefined || !myTherapist) ? <Modal>
          <div className={styles.loadingSpinner}>
            <Spin
              className={styles.spinner}
              indicator={
                <LoadingOutlined
                  color={'#3576bb'}
                  style={{fontSize: 32}}
                  spin
                />
              }
            />
          </div>
        </Modal> :
        <div className={styles.appointmentBooking}>
          <div className={styles.appointmentContainer}>
            <div className={styles.appointmentTitle}>
              <p>{isPsychiatry ? 'Prenota il tuo appuntamento' : 'Prenota il tuo appuntamento gratuito'}</p>
            </div>
            <Button
              leftIcon={<ArrowUturnLeftIcon/>}
              className="small"
              variant="secondary"
              onClick={async () => {
                await handleGoToChat()
              }}
            >
              Oppure vai alla chat
            </Button>
            <div className={styles.appointmentHeader}>
              Seleziona giorno e orario che preferisci:
              {!currentUserLocationIsItaly() &&
                <p>Ricorda che l'orario, in ogni nostra comunicazione, si riferisce al fuso orario italiano</p>}
            </div>
            <div className={styles.appointmentBlock}>
              {availableDates.map((appointment: any, index: number) => (
                <div key={index} className={styles.dateCard}>
                  <div className={styles.dateCardTitle}>
                    {new Date(appointment.day).toLocaleDateString('it-IT', {weekday: 'long'})}
                    <span>
                      <div>
                        {new Date(appointment.day).toLocaleDateString('it-IT', {
                          day: '2-digit',
                          month: 'long'
                        })}
                      </div>
                    </span>
                  </div>
                  <div className={styles.slotsContainer}>
                    {appointment.availableTime.map((availableTime: {
                      time: string,
                      available: boolean,
                      offline: boolean
                    }, timeIndex: string) => {
                      return (
                        <div
                          key={timeIndex}
                          className={`${styles.slot} ${selectedDateTime === `${appointment.day} ${availableTime.time}:00` ? styles.selected : ''} ${!availableTime.available ? styles.disabled : ''}`}
                          onClick={() => handleDateClick(appointment.day, availableTime.time, availableTime.available)}
                        >
                          {availableTime.time}
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
            <Button className={styles.appointmentBlockButton} variant="primary" onClick={async () => {
              await handleGoToChat()
            }}>Oppure vai alla chat</Button>
          </div>
          <div className={styles.therapistContainer}>
            <TherapistCard therapist={myTherapist}/>
            {(showChooseTherapistModal && !!suggestedTherapists?.length) &&
              <Modal modal subClassName="suggestedTherapists" close={() => {
                setShowChooseTherapistsModal(false);
              }}>
                <FormHeader
                  title={isPsychiatry ? 'Scegliere psichiatra' : 'Scegliere un terapeuta'}
                  closeModal={() => {
                    setShowChooseTherapistsModal(false);
                  }}
                  closeButton
                />
                <div className={styles.suggestedTherapistsWrapper}>
                  {suggestedTherapists?.map((therapist: any, index: number) => {
                    return (
                      <div key={index} className={styles.therapistInfoWrapper}>
                        <TherapistCard therapist={therapist} isMultiCards={true} index={index}/>
                      </div>
                    )
                  })}
                </div>
              </Modal>
            }
            {(showCurrentTherapistModal && myTherapist) &&
              <ModalCurrentTherapist
                className="modalWithSmallHeader"
                therapist={myTherapist}
                modalClose={() => setShowCurrentTherapistModal(false)}
                goToFeedbackModal={() => setShowFeedbackModal(true)}
              />
            }
            {showFeedbackModal && (
              <TherapistFeedbackModal
                className="modalWithSmallHeader"
                myTherapist={myTherapist}
                userInfo={userInfo?.data?.user}
                modalClose={() => setShowFeedbackModal(false)}
              />
            )}
            {selectedDateTime && (
              <div className={styles.appointmentWrapper}>
                <div className={styles.appointmentDateWrapper}>
                  <div className={styles.appointmentDate}>
                    {getNormalizedDate(selectedDateTime).toLocaleDateString('it-IT', {weekday: 'long'})}
                    <span>
                   <div>{getNormalizedDate(selectedDateTime).toLocaleDateString('it-IT', {
                     day: '2-digit',
                     month: 'long'
                   })}
                     </div>
                      {new Date(selectedDateTime).toLocaleTimeString('it-IT', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                  </span>
                    {'-'}
                  </div>
                  <div className={styles.appointmentName}>
                    {myTherapist?.given_name} {myTherapist?.family_name}
                  </div>
                </div>
                <Button className={styles.appointmentButton} variant='primary'
                        onClick={async () => createFirstAppointment(isAllFieldsFilled)}>
                  Conferma
                </Button>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};
