import {
  CalendarIcon,
  ChatBubbleBottomCenterTextIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  DocumentTextIcon,
  CalendarDaysIcon
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";
import logo from "../../../theme/images/logo-new.webp";
import Sidebar from "../../atoms/Sidebar";
import styles from "./Menu.module.scss";
import { useAppDispatch } from "customHooks/reduxHooks";
import { trpc } from "utils/trpc";
import { useEffect, useState } from "react";
import Modal from "../Modal";
import Button from "components/atoms/Button";
import { setPatientOnBoarding } from "store/slices/onBoardingDoneSlice";
import routes from "routes";
import { Socket } from "socket.io-client";
import { envs } from "../../../utils/envs";
import { raiseException } from "../../../utils/raiseException";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { setPatientName } from '../../../store/slices/patientInChatSlice';
import { useTherapistData } from '../../../customHooks/therapistDataContext';

interface Props {
  className?: string;
  closeMenu: () => void;
}

const appSiteUrl = envs.REACT_APP_SITE_URL;
const appHomeUrl = envs.REACT_APP_FRONTEND_URL;

export const Menu = ({ className = "", closeMenu }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, state } = location;
  const socket = useRouteLoaderData("root") as Socket;
  const {therapistData} = useTherapistData();

  const [onboardingStep, setOnboardingStep] = useState(0);

  const { data: userData, refetch } =
    trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined});
  const onboarding = trpc.mainService.user.completeOnboarding.useMutation();

  const getChatsResponse = trpc.mainService.chat.getChats.useQuery();
  const readChatMessages = trpc.mainService.chat.readChatMessages.useMutation();

  const completeOnboarding = () => {
    onboarding
      .mutateAsync()
      .then(async (data) => {
        await refetch();
        setOnboardingStep(0);
      })
      .catch((e) => {
        raiseException(e);
        console.error(e?.shape?.message)
      });
  };

  const isPatient = userData?.user.role === 'patient'
  const isPatientPsychiatry = (therapistData?.therapyType ?? userData?.user?.therapyType) === 'psychiatry'
  const isPatientUnderage = (therapistData?.therapyType ?? userData?.user?.therapyType) === 'underage'
  const isTherapistPsychiatry = userData?.user?.therapies?.includes('psychiatry')

  useEffect(() => {
    if (
      pathname === routes.Chat.path &&
      userData?.user &&
      userData.user.role === "patient" &&
      (userData.user.onboarding || state?.onboarding) &&
      onboardingStep === 0
    ) {
      navigate(routes.Chat.path);
      setOnboardingStep(1);
      closeMenu();
    }
  }, [userData?.user, state]);

  useEffect(() => {
    if (onboardingStep > (isPatientPsychiatry ? 6 : isPatientUnderage ? 4 : 5)) {
      dispatch(setPatientOnBoarding(true));
      setOnboardingStep(0);
    }
    if (onboardingStep === (isPatientPsychiatry ? 7 : isPatientUnderage ? 5 : 6)) {
      closeMenu();
    }
  }, [onboardingStep]);

  useEffect(() => {
    const onNotification = async () => {
      getChatsResponse.refetch();
    };
    socket.on("notification", onNotification);
    return () => {
      socket.off("notification", onNotification);
    };
  }, [userData]);


  return (
    <div className={`${styles.menuBackdrop} ${className}`} onClick={closeMenu}>
      <div
        className={`${styles.menu} ${
        onboardingStep && styles.onboardingMenu
        }`} onClick={e => {
        e.stopPropagation()
      }}
    >
      {userData ?
        <>
      {onboardingStep !== 0 && <Modal></Modal>}
          <>
        <div className={styles.header}>
          <img
            className={styles.logo}
            src={logo}
            alt="logo"
            onClick={() => window.location.href = `${appHomeUrl}`}
          />
          <div className={styles.closeIcon} onClick={closeMenu}>
            <XMarkIcon width={24}/>
          </div>
        </div>
            <div className={styles.wrapperBtn}>
              {isPatient && !isPatientUnderage &&
                <>
                  <Sidebar
                    className={onboardingStep === 1 ? styles.onboarding : ''}
                    active={pathname === routes.MultiProfile.path}
                    leftIcon={<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                   fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                   strokeLinejoin="round">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <circle cx="18" cy="18" r="3"></circle>
                        <circle cx="6" cy="6" r="3"></circle>
                        <path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
                        <line x1="6" y1="9" x2="6" y2="21"></line>
                      </g>
                    </svg>}
                    onClick={() => {
                      if (onboardingStep === 0) {
                        navigate(routes.MultiProfile.path);
                        closeMenu();
                      }
                    }}
                  >
                    <div className={styles.chatTitle}>
                      <span>Percorsi</span>
                      {getChatsResponse.data?.chats &&
                        getChatsResponse.data?.chats.reduce(
                          (unreadMessages, chat) =>
                            unreadMessages + chat.unreadMessages,
                          0
                        ) > 0 && (
                          <span
                            className={`${styles.patientChatCardNewMessage} ${styles[therapistData?.therapyType]}`}>
                    {getChatsResponse.data?.chats.reduce(
                      (unreadMessages, chat) =>
                        unreadMessages + chat.unreadMessages,
                      0
                    )}
                  </span>
                        )}
                    </div>
                    <div
                      className={
                        onboardingStep === 1
                          ? styles.onboardingDescription
                          : styles.hideStep
                      }
                    >
                      <div className={styles.triangle}></div>
                      <span>
                        È possibile modificare o aggiungere uno specialista qui.
                      </span>
                      <div className={styles.wrtapperBtn}>
                        <Button variant="tertiary" onClick={() => completeOnboarding()}>
                          Salta
                        </Button>
                        <Button onClick={() => setOnboardingStep(onboardingStep + 1)}>
                          Avanti
                        </Button>
                      </div>
                    </div>
                  </Sidebar>
                  <div className={styles.spacer}></div>
                </>
              }
              <Sidebar
                className={onboardingStep === (isPatientUnderage ? 1 : 2) ? styles.onboarding : ''}
                active={pathname === routes.Chat.path}
                leftIcon={isPatient ? <ChatBubbleBottomCenterTextIcon/> : <DocumentTextIcon/>}
                onClick={async () => {
                  if (onboardingStep === 0) {
                    if (userData?.user.role === 'patient') {
                      if (getChatsResponse.data?.chats[0]) {
                        try {
                          await readChatMessages.mutateAsync({
                            chatId: therapistData?.chat?.id || getChatsResponse.data?.chats[0]?.id,
                          });
                          await getChatsResponse.refetch();
                        } catch (e: any) {
                          raiseException(e);
                          console.error(e);
                        }
                      }
                    }
                    navigate(routes.Chat.path);
                    dispatch(setPatientName({name: '', surname: '', id: ''}));
                    closeMenu();
                  }
                }}>
                <div className={styles.chatTitle}>
                  <span>{isPatient ? 'Chat' : 'Pazienti'}</span>
                    {getChatsResponse.data?.chats && !isPatient &&
                      getChatsResponse.data?.chats.reduce(
                        (unreadMessages, chat) => unreadMessages + chat.unreadMessages, 0
                      ) > 0 && (
                        <span
                          className={`${styles.patientChatCardNewMessage} ${isPatientPsychiatry || isTherapistPsychiatry ? styles.patientChatCardNewMessagePsychiatry : ''}`}>
                                {getChatsResponse.data?.chats.reduce(
                                  (unreadMessages, chat) => unreadMessages + chat.unreadMessages, 0
                                )}
                            </span>
                      )}
                  </div>
                <div
                  className={
                    onboardingStep === (isPatientUnderage ? 1 : 2)
                      ? styles.onboardingDescription
                      : styles.hideStep
                  }>
                  <div className={styles.triangle}></div>
                  <span>
                      Qui potrai chattare con il tuo {isPatientPsychiatry ? 'psichiatra' : 'terapeuta'} e programmare gli
                      appuntamenti.
                    </span>
                  <div className={styles.wrtapperBtn}>
                    <Button variant="tertiary" onClick={() => completeOnboarding()}>
                      Salta
                    </Button>
                    <Button onClick={() => setOnboardingStep(onboardingStep + 1)}>
                      Avanti
                    </Button>
                  </div>
                </div>
              </Sidebar>
              <div className={styles.spacer}></div>
          <Sidebar
            className={onboardingStep === (isPatientUnderage ? 2 : 3) ? styles.onboarding : ''}
            active={pathname === routes.Appointments.path}
            leftIcon={<CalendarIcon/>}
            onClick={() => {
              if (onboardingStep === 0) {
                navigate(routes.Appointments.path);
                closeMenu();
              }
            }}
          >
            Appuntamenti
            <div
              className={
                onboardingStep === (isPatientUnderage ? 2 : 3)
                  ? styles.onboardingDescription
                  : styles.hideStep
              }
            >
              <div className={styles.triangle}></div>
              <span>
                La sezione “Appuntamenti” ti mostrerà la lista delle sedute
                passate e programmate.
              </span>
              <div className={styles.wrtapperBtn}>
                <Button variant="tertiary" onClick={() => completeOnboarding()}>
                  Salta
                </Button>
                <Button onClick={() => setOnboardingStep(onboardingStep + 1)}>
                  Avanti
                </Button>
              </div>
            </div>
          </Sidebar>
          {isPatient &&
            <>
              <div className={styles.spacer}></div>
              {isPatientPsychiatry && (
                <>
                  <Sidebar
                    className={onboardingStep === (isPatientUnderage ? 3 : 4) ? styles.onboarding : ''}
                    active={pathname.includes(routes.PatientBookingFirstAppointment.path)}
                    leftIcon={<CalendarDaysIcon/>}
                    onClick={() => {
                      if (onboardingStep === 0) {
                        navigate(routes.PatientBookingFirstAppointment.path);
                        closeMenu();
                      }
                    }}>
                      Prenota Appuntamento
                    <div className={
                      onboardingStep === (isPatientUnderage ? 3 : 4)
                          ? styles.onboardingDescription
                          : styles.hideStep
                      }>
                        <div className={styles.triangle}></div>
                        <span>
                          È possibile prenotare un appuntamento su questa pagina.
                        </span>
                        <div className={styles.wrtapperBtn}>
                          <Button variant="tertiary" onClick={() => completeOnboarding()}>
                            Salta
                          </Button>
                          <Button onClick={() => setOnboardingStep(onboardingStep + 1)}>
                            Avanti
                          </Button>
                        </div>
                    </div>
                  </Sidebar>
                  <div className={styles.spacer}></div>
                </>
              )}
              <Sidebar
                className={onboardingStep === (isPatientPsychiatry ? 5 : isPatientUnderage ? 3 : 4) ? styles.onboarding : ''}
                active={pathname.includes(routes.ClinicalPapers.path)}
                leftIcon={<DocumentTextIcon/>}
                onClick={() => {
                  if (onboardingStep === 0) {
                    navigate(routes.ClinicalPapers.path);
                    closeMenu();
                  }
                }}
              >
                {isPatientPsychiatry ? 'Documenti Clinici' : 'Allegati'}
                <div
                  className={
                    onboardingStep === (isPatientPsychiatry ? 5 : isPatientUnderage ? 3 : 4)
                      ? styles.onboardingDescription
                      : styles.hideStep
                  }
                >
                  <div className={styles.triangle}></div>
                  <span>
               {isPatientPsychiatry ? 'Nel tuo profilo puoi vedere le tue prescrizioni e aggiungere documentazione sanitaria.' : 'Nel proprio profilo è possibile visualizzare i documenti inviati e ricevuti.'}
              </span>
                  <div className={styles.wrtapperBtn}>
                    <Button variant="tertiary" onClick={() => completeOnboarding()}>
                      Salta
                    </Button>
                    <Button onClick={() => setOnboardingStep(onboardingStep + 1)}>
                      Avanti
                    </Button>
                  </div>
                </div>
              </Sidebar>
            </>
          }
          <div className={styles.spacer}></div>
          <Sidebar
            className={onboardingStep === (isPatientPsychiatry ? 6 : isPatientUnderage ? 4 : 5) ? styles.onboarding : ''}
            active={pathname.includes(routes.Profile.path)}
            leftIcon={<UserIcon/>}
            onClick={() => {
              if (onboardingStep === 0) {
                navigate(routes.Profile.path);
                closeMenu();
              }
            }}
          >
            Profilo
            <div
              className={
                onboardingStep === (isPatientPsychiatry ? 6 : isPatientUnderage ? 4 : 5)
                  ? styles.onboardingDescription
                  : styles.hideStep
              }
            >
              <div className={styles.triangle}></div>
              <span>
                All’interno del tuo profilo potrai modificare le informazioni
                personali, impostare il metodo di pagamento e compilare il
                consenso informato.
              </span>
              <div className={styles.wrtapperBtn}>
                <Button variant="tertiary" onClick={() => completeOnboarding()}>
                  Salta
                </Button>
                <Button onClick={() => setOnboardingStep(onboardingStep + 1)}>
                  Avanti
                </Button>
              </div>
            </div>
          </Sidebar>
        </div>
          </>
      <div className={styles.helpBtn}>
        <Sidebar
          leftIcon={<QuestionMarkCircleIcon/>}
          active={pathname === routes.DashboardFaq.path}
          onClick={() => {
            navigate(routes.DashboardFaq.path);
            closeMenu();
          }}
        >
          Assistenza
        </Sidebar>
      </div>
        </>
        : <Spin
          className={styles.spinner}
          indicator={
            <LoadingOutlined
              style={{fontSize: 15, color: '#2C2C4B'}}
              spin
            />
          }
        />
      }
      </div>
    </div>
  );
};
