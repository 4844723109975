import styles from "./TherapistFeedbackModal.module.scss"
import FormHeader from "../../atoms/FormHeader";
import Button from "../../atoms/Button";
import { useState } from "react";
import { trpc } from "../../../utils/trpc";
import { queryClient } from "../../../index";
import { setInfo } from "../../../store/slices/infoSlice";
import { useAppDispatch } from "../../../customHooks/reduxHooks";
import Emitter from "../../../utils/eventemitter";
import { raiseException } from "../../../utils/raiseException";
import Modal from "../Modal";
import { useTherapistData } from '../../../customHooks/therapistDataContext';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';

export const TherapistFeedbackModal = ({userInfo, myTherapist, modalClose, className}: any) => {
	const [changeMotive, setChangeMotive] = useState("");
	const {therapistData, setTherapistData, removeTherapistData} = useTherapistData();
	const navigate = useNavigate();

	const gender = userInfo.gender


	const RadioInput = ({id, option, checked, style, onChange}) => {
		return (
			<label htmlFor={id} className={checked ? style : ''}>
				<input
					id={id}
					type="radio"
					value={option.value}
					checked={checked}
					onChange={onChange}
				/>
				{option.value}
			</label>
		);
	}

	const isPsychiatry = myTherapist?.therapyType?.includes("psychiatry");

	const dispatch = useAppDispatch();

	const getSurvey: any = trpc.mainService.additionalAccounts.getAdditionalAccountSurvey.useQuery(
		{therapyType: therapistData?.therapyType || userInfo.therapyType}, {
			enabled: !(changeMotive.trim() === '')
		}
	);

	const getSuggestedTherapist = trpc.mainService.patient.getSuggestedTherapists.useQuery({survey: getSurvey?.data?.survey}, {
		enabled: getSurvey.isSuccess
	});

	const ChangeTherapistFeedback = [
		{
			value: `Il profilo del ${isPsychiatry ? "psichiatra " : "terapeuta "} attuale non rispecchia le mie esigenze`
		},
		{
			value: `Ho bisogno di un ${isPsychiatry ? "psichiatra " : "terapeuta "} con competenze specifiche`
		},
		{
			value: `Non mi sento a mio agio con il  ${isPsychiatry ? "psichiatra" : "terapeuta"} attuale`
		},
		{
			value: `Le mie disponibilità orarie non coincidono con quelle del ${isPsychiatry ? "psichiatra" : "terapeuta"}`
		},
		{
			value: "Vorrei modificare le mie risposte al questionario"
		},
		{
			value: "Altro"
		},
	]

	const chooseTherapist =
		trpc.mainService.patient.chooseTherapist.useMutation();

	const additionalAccount = trpc.mainService.additionalAccounts.additionalAccount.useMutation();

	const sendChangeTherapistFeedback =
		trpc.mainService.patient.sendChangeTherapistFeedback.useMutation();

	const handleError = (e: any, title: string) => {
		raiseException(e);
		dispatch(
			setInfo({
				variant: 'error',
				text: e.message,
				title,
			})
		);
	};

	const emitUpdates = () => {
		queryClient.removeQueries();
			navigate(routes.Chat.path);
		Emitter.emit('updateInfo');
	};

	const getAdditionalTherapist = async () => {
		try {
			const data: any = await additionalAccount.mutateAsync({
				survey: getSurvey?.data.survey,
				actualTherapistId: therapistData?.therapistId || myTherapist?.id,
			});

			if (data) {
				const chatObject = data?.therapist?.actualChat[0];

				setTherapistData({
					therapistId: data?.therapist?.id,
					chat: {
						id: chatObject?.id,
						therapyType: chatObject?.therapyType,
						createdAt: chatObject?.createdAt
					},
					therapyType: chatObject?.therapyType,
					changeTherapist: data?.therapist?.changeTherapist,
					main: userInfo.therapistId === data?.therapist?.id
				});

				emitUpdates();
				modalClose(false);
			}
		} catch (e) {
			handleError(e, 'Errore scelta terapeuta');
		}
	};

	const getTherapist = async (id: string) => {
		try {
			await chooseTherapist.mutateAsync({
				therapistId: id,
				therapistChange: true,
				accountTherapyType: therapistData?.therapyType || userInfo.therapyType,
			});

			removeTherapistData();
			emitUpdates();
			modalClose(false);
		} catch (e) {
			handleError(e, 'Errore scelta terapeuta');
		}
	};

	const changeTherapist = async () => {
		try {
			if (therapistData?.main) {
				const getTherapists = await getSuggestedTherapist.refetch();
				await sendChangeTherapistFeedback.mutateAsync({feedback: changeMotive});

				const suggestedTherapist: any = getTherapists.data?.suggestedTherapists[0];
				if (suggestedTherapist) {
					await getTherapist(suggestedTherapist.id);
				}
			} else {
				await sendChangeTherapistFeedback.mutateAsync({
					feedback: changeMotive,
					accountTherapyType: therapistData?.therapyType || userInfo.therapyType,
					additionalTherapistId: therapistData?.therapistId || myTherapist?.id,
				});

				await getAdditionalTherapist();
			}
		} catch (error: any) {
			handleError(error, 'Errore cambio terapeuta');
			console.error('Error', error);
		}
	};

	const closeFeedbackModal = () => {
		modalClose(false);
	}

	return (
		<Modal
			modal
			className={className}
			subClassName={className}
			close={() => {
				closeFeedbackModal();
			}}
		>
			<div className={styles.changePreferences} onClick={e => {
				e.stopPropagation()
			}}>
				<FormHeader
					closeModal={() => closeFeedbackModal()}
					title={`Sei sicur${
						gender === 'male'
							? 'o'
							: gender === 'female'
								? 'a'
								: '*'
					} di voler cambiare ${isPsychiatry ? "psichiatra " : "terapeuta "}?`}
					closeButton
				/>
				<div className={styles.description}>
					Ti consigliamo di parlarne prima con il tuo {isPsychiatry ? "psichiatra " : "terapeuta "} per capire
					se effettivamente il tuo percorso non soddisfa le tue aspettative
					e quindi provare a vedere se un altra scelta possa fare al caso
					tuo. Motiva la scelta qui sotto:
				</div>
				<div className={styles.radioWrapper}>
					{ChangeTherapistFeedback.map((item, index) => (
						<RadioInput
							key={index}
							id={`option-${index}`}
							option={item}
							style={styles.checked}
							checked={changeMotive === item.value}
							onChange={(e: any) => {
								setChangeMotive(e.target.value)
							}}
						/>
					))}
				</div>
				<div className={styles.btnWrapper}>
					<Button
						variant="secondary"
						onClick={() => closeFeedbackModal()}
					>
						Annulla
					</Button>
					<Button
						disabled={changeMotive.trim() === ''}
						onClick={() => {
							changeTherapist()
						}}
					>
						Avanti
					</Button>
				</div>
			</div>
		</Modal>
	)
}
